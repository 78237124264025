import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import darkLogo from "../../assets/DarkLogo.png";
import LanguageSwitcher from "../LanguageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { Fade as Hamburger } from "hamburger-react";
import arrowDownIcon from "../../assets/arrow_down.svg";
import forexNavImg from "../../assets/forex-nav.png";
import usaNavImg from "../../assets/usaberze-nav.png";
import montenegroNavImg from "../../assets/montenegroberze-nav.png";
import tfStednja from "../../assets/tfStednja.png";
import tfTrader from "../../assets/TfTraderProd.png";
import forexProd from "../../assets/ForexProd.png";
import mnseProd from "../../assets/mnseProd.png";
import tfStednja1 from "../../assets/tfStednja1.png";
import appleStore from "../../assets/appdwnldApple.png";
import googlePlay from "../../assets/appGooglePlay.png";
import qrCodeImg from "../../assets/qr_code_hero.svg";

const HomeIcon = (props) => {
  const location = useLocation();
  const isStednjaPage = location.pathname.includes('/stednja');
  return (
    <svg
      className={
        `font-bold text-[#161616] hover:text-[#345BCC] transition-colors ease-in-out duration-200 ${isStednjaPage && "text-[#FFFFFF] hover:text-[#C8E64C]"}`
      }
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8.00001L14 2.74001C13.45 2.24805 12.7379 1.97607 12 1.97607C11.2621 1.97607 10.55 2.24805 10 2.74001L4 8.00001C3.68237 8.28408 3.4289 8.63256 3.25648 9.02225C3.08405 9.41194 2.99662 9.83389 3 10.26V19C3 19.7957 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H18C18.7957 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7957 21 19V10.25C21.002 9.82557 20.9139 9.40555 20.7415 9.01769C20.5691 8.62983 20.3164 8.28296 20 8.00001ZM14 20H10V15C10 14.7348 10.1054 14.4804 10.2929 14.2929C10.4804 14.1054 10.7348 14 11 14H13C13.2652 14 13.5196 14.1054 13.7071 14.2929C13.8946 14.4804 14 14.7348 14 15V20ZM19 19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H16V15C16 14.2044 15.6839 13.4413 15.1213 12.8787C14.5587 12.3161 13.7957 12 13 12H11C10.2044 12 9.44129 12.3161 8.87868 12.8787C8.31607 13.4413 8 14.2044 8 15V20H6C5.73479 20 5.48043 19.8946 5.2929 19.7071C5.10536 19.5196 5 19.2652 5 19V10.25C5.00018 10.108 5.0306 9.9677 5.08922 9.83839C5.14784 9.70907 5.23333 9.59372 5.34 9.50001L11.34 4.25001C11.5225 4.08969 11.7571 4.00127 12 4.00127C12.2429 4.00127 12.4775 4.08969 12.66 4.25001L18.66 9.50001C18.7667 9.59372 18.8522 9.70907 18.9108 9.83839C18.9694 9.9677 18.9998 10.108 19 10.25V19Z"
        fill="currentColor"
      />
    </svg>
  );
};

const ArrowMobile = (props) => {
  const location = useLocation();
  const isStednjaPage = location.pathname.includes('/stednja');
  return (
    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 6H12M12 6L7 1M12 6L7 11" stroke={isStednjaPage ? "white" : "#1A1A1A"} stroke-width="1.5" />
    </svg>

  );
};

const Products = () => {
  const { t } = useTranslation("header");
  const location = useLocation();
  const isStednjaPage = location.pathname.includes('/stednja');

  useEffect(() => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, []);


  return (
    <div
      style={{ boxShadow: ' 0px 4px 4px 0px #00000040' }}
      className="absolute flex flex-col bg-[#080F2033] gap-3 w-full h-screen z-[5] pointer-events-auto"
    >
      <div className={`lg:max-w-[1200px] backdrop-blur-[10px] z-[10]  ${isStednjaPage && "!bg-[#FFFFFF21] "} xl:max-w-[1500px] pb-[82px] w-full mx-auto bg-white pt-[128px]`}>
        {/* <div className="absolute top-0 left-0 z-[10] backdrop-blur-2xl w-full h-screen bg-white/50"></div> */}
        <div className=" flex items-center justify-center gap-[16px] relative z-[20]">
          <div className="w-[285px] h-[436px]">
            <div className="h-[220px] w-full">
              <img src={tfStednja} alt="TF Štednja" className="w-full h-full object-cover" />
            </div>
            <div className={`p-[24px] ${isStednjaPage && "bg-[#080F20]"}`} style={{ boxShadow: `${!isStednjaPage && "0px 4px 8px 0px #B1B1B14D"}` }}>
              <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                <p>TF Trader</p>
              </div>
              <div className={`${isStednjaPage && "text-white"} pt-[32px] pb-[16px]`}>
                <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">TF Štednja</p>
                <p className="text-[14px] leading-[16.8px]">Uštedi. Uloži. Isplati kad želiš.</p>
              </div>
              <div className="flex items-center justify-center gap-[8px]">
                <a href="https://tfcapital.me/stednja" target="_blank"><button className="w-[114.5px] h-[38px] bg-[#3E6BE4] hover:bg-[#527FF5] rounded-[50px] text-white text-[14px] leading-[14px]">Saznaj više</button></a>
                <div className="relative inline-block group">
                  <button className={`w-[114.5px] ${!isStednjaPage ? "border-[#3E6BE4] text-[#3E6BE4]" : " border-[white] text-[white]"} h-[38px] border-[1px] rounded-[50px] hover:bg-white hover:text-[black] leading-[14px] text-[14px]`}>
                    Otvori račun
                  </button>

                  <div className="absolute bg-[#3E6BE4] p-1 w-[100px] h-[100px] bottom-[-110px] left-2 rounded-[8px] text-[#3E6BE4] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                  >
                    <img
                      className="w-full h-full"
                      src={qrCodeImg} />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className={`h-full bg-[#CCD0D7] ${isStednjaPage && "bg-[#CCD0D740]"} w-[1px]`}></div>

          <div className="w-[285px] h-[436px]">
            <div className="h-[220px] w-full">
              <img src={tfTrader} alt="TF Trader aplikacija" />
            </div>
            <div className={`p-[24px] ${isStednjaPage && "bg-[#080F20]"}`} style={{ boxShadow: `${!isStednjaPage && "0px 4px 8px 0px #B1B1B14D"}` }}>
              <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                <p>USA Berze</p>
              </div>
              <div className={`${isStednjaPage && "text-white"} pt-[32px] pb-[16px]`}>
                <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">TF Trader™</p>
                <p className="text-[14px] leading-[16.8px]">Kupi. Prodaj. Bez odlaska u banku.</p>
              </div>
              <div className="flex items-center justify-center gap-[8px]">
                <a href="https://tfcapital.me/usa-berza" target="_blank"><button className="w-[114.5px] h-[38px] bg-[#3E6BE4] hover:bg-[#527FF5] rounded-[50px] text-white text-[14px] leading-[14px]">Saznaj više</button></a>
                <div className="relative inline-block group">
                  <button className={`w-[114.5px] ${!isStednjaPage ? "border-[#3E6BE4] text-[#3E6BE4]" : " border-[white] text-[white]"} h-[38px] border-[1px] rounded-[50px] hover:bg-white hover:text-[black] leading-[14px] text-[14px]`}>
                    Otvori račun
                  </button>

                  <div className="absolute bg-[#3E6BE4] p-1 w-[100px] h-[100px] bottom-[-110px] left-2 rounded-[8px] text-[#3E6BE4] opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                  >
                    <img
                      className="w-full h-full"
                      src={qrCodeImg} />
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div className={`h-full bg-[#CCD0D7] ${isStednjaPage && "bg-[#CCD0D740]"} w-[1px]`}></div>


          <div className="w-[285px] h-[436px]">
            <div className="h-[220px] w-full">
              <img src={forexProd} alt="Forex" />
            </div>
            <div className={`p-[24px] ${isStednjaPage && "bg-[#080F20]"}`} style={{ boxShadow: `${!isStednjaPage && "0px 4px 8px 0px #B1B1B14D"}` }}>
              <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                <p>Forex</p>
              </div>
              <div className={`${isStednjaPage && "text-white"} pt-[32px] pb-[16px]`}>
                <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">Forex</p>
                <p className="text-[14px] leading-[16.8px]">Bez komplikovanih procedura.</p>
              </div>
              <div className="flex items-center justify-center gap-[8px]">
                <a href="https://tfcapital.me/forex" target="_blank"><button className={`w-[114.5px] h-[38px] ${isStednjaPage && "!bg-white !text-black"} bg-[black] rounded-[50px] text-white text-[14px] leading-[14px]`}>Saznaj više</button></a>
                <a href="https://my.tfcapital.me/register" target="_blank"> <button className={`w-[114.5px] h-[38px] border-[1px] ${isStednjaPage && "!border-white text-[white] hover:bg-white hover:text-[black]"} border-[black] rounded-[50px] text-[black] leading-[14px] text-[14px]`}>Otvori račun</button></a>
              </div>
            </div>
          </div>

          <div className={`h-full bg-[#CCD0D7] ${isStednjaPage && "bg-[#CCD0D740]"} w-[1px]`}></div>


          <div className="w-[285px] h-[436px]">
            <div className="h-[220px] w-full">
              <img src={mnseProd} alt="MNSE" />
            </div>
            <div className={`p-[24px] ${isStednjaPage && "bg-[#080F20]"}`} style={{ boxShadow: `${!isStednjaPage && "0px 4px 8px 0px #B1B1B14D"}` }}>
              <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                <p>MNSE</p>
              </div>
              <div className={`${isStednjaPage && "text-white"} pt-[32px] pb-[16px]`}>
                <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">Montenegroberza</p>
                <p className="text-[14px] leading-[16.8px]">Bez komplikovanih procedura.</p>
              </div>
              <div className="flex items-center justify-center gap-[8px]">
                <a href="https://tfcapital.me/montenegroberze" target="_blank"><button className="w-[114.5px] h-[38px] bg-[#148480] rounded-[50px] text-white text-[14px] leading-[14px]">Saznaj više</button></a>
                <a href="https://tfcapital.me/montenegroberze#montenegro-form" target="_blank"> <button className={`w-[114.5px] h-[38px] border-[1px] border-[#37B2AD] ${isStednjaPage && "border-[white] text-[white] hover:bg-white hover:text-[black]"} rounded-[50px] text-[#37B2AD] leading-[14px] text-[14px]`}>Prijavi se</button></a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};


function Header() {
  const { t } = useTranslation("header");
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const location = useLocation();
  const isStednjaPage = location.pathname.includes('/stednja');
  const isForexPage = location.pathname.includes('/forex');

  // useEffect(() => {
  //   if (isMenuOpened) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "auto";
  //   }
  // }, [isMenuOpened]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      setIsHeaderFixed(scrollPosition > 32);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fixed z-[9999] h-[screen] w-screen">
      {isProductModalOpen && <Products />}
      <div
        id="header"
        className={`${isHeaderFixed
          ? `${isMenuOpened
            ? `bg-white  ${isStednjaPage && "bg-[#080F20] "}`
            : `bg-white/50 !backdrop-blur-[10px]  ${isStednjaPage && "!bg-[#080F20]/50"} `
          }    `
          : `${isMenuOpened ? `bg-white backdrop-blur-[10px] ${isStednjaPage && "!bg-[#080F20]"} ` : "bg-transparent"
          } shadow-none`

          }  transition-colors duration-500 ease-in-out fixed  top-0 left-0 w-full z-[99999999]`}
      >

        <div className="container mx-auto lg:px-[20px] px-[16px] 2xl:px-[50px] relative z-[9999]">
          <div className=" flex items-center justify-between 2xl:pt-[25px] 2xl:pb-[25px] py-[16px] lg:pt-[30px] lg:pb-[25px] ">
            <div className="flex items-center justify-start gap-[28.47px]">
              <NavLink to="/" onClick={() => setIsProductModalOpen(false)}>
                <img
                  src={isStednjaPage ? darkLogo : logo}
                  alt="logo"
                  className="2xl:w-[130px] md:w-[100px] w-[90px]"
                />
              </NavLink>
              <div style={{ boxShadow: isStednjaPage && "0px 4px 6px 0px #3E6BE40A" }} className={`h-[48px] py-[4px] px-[8px] backdrop-blur-[3px] ${isStednjaPage && "!bg-[#FFFFFF21]"} bg-[#F0F0F0B2] border-[1px] border-[#FFFFFF21] rounded-[8px] lg:flex gap-[8px] items-center hidden text-black font-medium lg:text-[14px] lg:leading-[16.94px] 2xl:text-base 2xl:leading-[20px]`}>
                <NavLink
                  to="/"
                >
                  <HomeIcon />
                </NavLink>

                <div className="h-full flex items-center gap-2.5 cursor-pointer p-[8px] group" onClick={() => setIsProductModalOpen(!isProductModalOpen)}>
                  <p
                    className={`transition-colors ease-in-out hover:text-[#345BCC] ${isStednjaPage && " hover:text-[#C8E64C]"} duration-200 ${location.pathname === "/usa-berza" ||
                      location.pathname === "/forex" ||
                      location.pathname === "/montenegroberze"
                      || location.pathname === "/stednja"
                      ? `font-medium ${isStednjaPage ? "text-[#FFFFFF]" : "text-[#3E6BE4]"}`
                      : ""

                      }`}
                  >
                    {t("products")}
                  </p>
                  <img className="w-[13px]" src={arrowDownIcon} alt="arrow_down" />
                </div>

                {/* 
            <NavLink
              aria-disabled
              // className={({ isActive }) =>
              //   isActive
              //     ? "font-bold border-b-[1px] border-black pb-[3px]"
              //     : "pb-[3px]"
              // }
              // to="/news"
              className="pb-[3px]"
            >
              {t("news")}
            </NavLink> */}

                {/* <NavLink
              className={({ isActive }) =>
                isActive ? "font-bold text-[#345BCC]" : ""
              }
              to="/about"
            >
              {t("whyTf")}
            </NavLink> */}
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? `font-medium text-[#345BCC] p-[8px]`
                      : `hover:text-[#345BCC] ${isStednjaPage && "hover:text-[#C8E64C] text-[#FFFFFF]"} transition-colors p-[8px] ease-in-out duration-200`
                  }
                  to="/news?filter=all"
                >
                  {t("news")}
                </NavLink>

                <NavLink
                  title="Uskoro"
                  className={({ isActive }) =>
                    isActive
                      ? "font-medium text-[gray] p-[8px]"
                      : `hover:text-[gray] transition-colors p-[8px] ease-in-out duration-200 ${isStednjaPage && "hover:text-[#C8E64C] text-[#FFFFFF]"}`
                  }
                // to="/education"
                >
                  {t("education")}
                </NavLink>

                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "font-medium text-[#345BCC] p-[8px]"
                      : `hover:text-[#345BCC] transition-colors p-[8px] ease-in-out duration-200 ${isStednjaPage && "hover:text-[#C8E64C] text-[#FFFFFF]"}`
                  }
                  to="/about"
                >
                  {t("about")}
                </NavLink>
              </div>
            </div>
            <div className="hidden lg:flex items-center gap-[31px]">
              <LanguageSwitcher />
              <a href={isForexPage ? "https://my.tfcapital.me/login" : "https://tfcapital.me/contact"}>
                <button className={`px-[17.5px] py-[14px] rounded-[50px] min-w-[115px]  transition-all ease-in-out duration-200 text-white text-xs font-semibold leading-[15px] ${isStednjaPage ? "bg-[#C8E64C] hover:bg-[#dcf560] !text-[#0B1328]" : "bg-[#3E6BE4] hover:bg-[#335DCC]"}`}>
                  {isForexPage ? `Log in` : t("contact_us")}
                </button>
              </a>
            </div>
            <div className="lg:hidden flex gap-0">
              <a href={isForexPage ? "https://my.tfcapital.me/login" : "https://tfcapital.me/contact"}>
                <button className="px-[17.5px] py-[14px] rounded-[50px] min-w-[115px] bg-[#3E6BE4] hover:bg-[#335DCC] transition-all ease-in-out duration-200 text-white text-xs font-semibold leading-[15px]">
                  {isForexPage ? `Log in` : t("contact_us")}
                </button>
              </a>
              <div
                onClick={() => setIsMenuOpened(!isMenuOpened)}
                className="gap-[3px] cursor-pointer rounded-[50px] px-[8px] flex lg:hidden items-center justify-center flex-col"
              >
                <Hamburger
                  toggled={isMenuOpened}
                  size={18}
                  color={isStednjaPage ? "white" : "#163A69"}
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`${isMenuOpened
            ? "opacity-1 z-[999999] translate-x-0 "
            : "opacity-0 z-0 translate-x-[100%] "

            } transition-opacity transform  duration-500 overflow-y-auto ease-in-out lg:hidden block mt-[80px] w-screen h-[calc(100vh-80px)] bg-white ${isStednjaPage && "!bg-[#080F20] text-white"} absolute top-0 left-0 `}
        >
          <div className={`text-[#A6A6A6] text-[16px] leading-[16px] font-medium flex flex-col text-left py-[32px]`}>
            <NavLink
              style={{ color: `${isStednjaPage ? "white" : "black"}` }}
              className={({ isActive }) =>
                isActive ? "font-medium text-[#3E6BE4] py-5 flex items-center justify-between text-[16px] border-t-[1px] border-b-[1px] border-[#CCD0D7] px-[16px]" : "px-[16px] py-5 flex items-center justify-between border-t-[1px] border-b-[1px] border-[#CCD0D7] text-[#1A1A1A]"
              }
              to="/"
              onClick={() => setIsMenuOpened(false)}
            >
              {t("home")}
              <ArrowMobile />
            </NavLink>

            <div
              onClick={() => setProductsOpen(!productsOpen)}
              style={{ color: `${isStednjaPage ? "white" : "black"}` }}
              className={`flex items-center justify-between py-5 px-[16px] border-b-[1px] border-[#CCD0D7] cursor-pointer relative ${location.pathname === "/usa-berza" ||
                location.pathname === "/forex" ||
                location.pathname === "/montenegroberze"
                ? "font-medium text-[#3E6BE4]"
                : "text-[#1A1A1A]"
                }`}
            >
              <p>{t("products")}</p>
              <svg className={`${productsOpen ? 'rotate-[180deg]' : 'rotate-0'}`} width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.39819 7.66292C7.17851 7.88259 6.82241 7.88259 6.60274 7.66292L0.867876 1.92804C0.648208 1.70837 0.648208 1.35227 0.867876 1.13259L1.13305 0.867393C1.35271 0.647718 1.70887 0.647718 1.92854 0.867393L7.00046 5.93934L12.0724 0.867393C12.2921 0.647718 12.6482 0.647718 12.8679 0.867393L13.1331 1.13259C13.3527 1.35227 13.3527 1.70837 13.1331 1.92804L7.39819 7.66292Z" fill={`${isStednjaPage ? "white" : "#1A1A1A"}`} />
              </svg>
            </div>

            <div
              className={`relative flex items-center justify-center mx-auto transition-all min-w-[350px] ease-in-out duration-500 w-full ${isStednjaPage && "!bg-[#080F20]"} bg-white ${productsOpen ? "h-[2950px] max-h-[2950px]" : "max-h-0 h-0"
                }`}
            >
              <div
                className={`flex flex-col w-full justify-center items-center gap-3 absolute transition-all ease-in-out duration-500 ${productsOpen
                  ? "top-0 left-0 opacity-100 pointer-events-auto"
                  : "-top-[50px] left-[40px] opacity-0 pointer-events-none"

                  }`}
              >
                <div className="w-[335px] mt-[32px] h-[687px] flex flex-col">
                  <div className="h-[328px] w-full">
                    <img src={tfStednja1} alt="TF Štednja" className="w-full h-full object-cover" />
                  </div>
                  <div className="p-[24px] w-full test-start" style={{ boxShadow: '0px 4px 8px 0px #B1B1B14D' }}>
                    <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                      <p>TF Trader</p>
                    </div>
                    <div className={`pt-[64px] pb-[16px] ${isStednjaPage ? "text-white" : "text-black"}`}>
                      <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">TF Štednja</p>
                      <p className="text-[14px] leading-[16.8px] font-normal">Uštedi. Uloži. Isplati kad želiš.</p>
                    </div>
                    <div className="flex items-start justify-start gap-[8px] mb-[48px]">
                      <a href="https://tfcapital.me/stednja" target="_blank"><button className="w-[114.5px] h-[38px] bg-[#3E6BE4] rounded-[50px] text-white text-[14px] leading-[14px]">Saznaj više</button></a>
                      <a href="https://onelink.to/48kpsn" target="_blank"><button className={`w-[114.5px] h-[38px] border-[1px] ${isStednjaPage ? "border-[white] text-[white]" : "border-black text-black"} border-[white] rounded-[50px] leading-[14px] text-[14px]`}>Otvori račun</button></a>
                    </div>
                    <div className="flex items-center justify-center gap-[3px]">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.66992 0.807617C3.35792 0.807617 0.669922 3.49562 0.669922 6.80762C0.669922 10.1196 3.35792 12.8076 6.66992 12.8076C9.98192 12.8076 12.6699 10.1196 12.6699 6.80762C12.6699 3.49562 9.98192 0.807617 6.66992 0.807617ZM7.26992 9.80762H6.06992V6.20762H7.26992V9.80762ZM7.26992 5.00762H6.06992V3.80762H7.26992V5.00762Z" fill="#788497" />
                      </svg>

                      <p className="text-[8px] leading-[12.8px] text-[#788497] pt-[1.5px]">Ne investiraj novac koji ne možeš priuštiti.</p>
                    </div>

                  </div>
                </div>

                <div className="w-[335px] mt-[32px] h-[687px] flex flex-col">
                  <div className="h-[328px] w-full">
                    <img src={tfTrader} alt="TF Trader" className="w-full h-full object-cover" />
                  </div>
                  <div className="p-[24px] w-full test-start" style={{ boxShadow: '0px 4px 8px 0px #B1B1B14D' }}>
                    <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                      <p>USA Berze</p>
                    </div>
                    <div className={`pt-[64px] pb-[16px] ${isStednjaPage ? "text-white" : "text-black"}`}>
                      <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">TF Trader™</p>
                      <p className="text-[14px] leading-[16.8px] font-normal">Kupi. Prodaj. Bez odlaska u banku.</p>
                    </div>
                    <div className="flex items-start justify-start gap-[8px] mb-[48px]">
                      <a href="https://tfcapital.me/usa-berza" target="_blank"><button className="w-[114.5px] h-[38px] bg-[#3E6BE4] rounded-[50px] text-white text-[14px] leading-[14px]">Saznaj više</button></a>
                      <a href="https://onelink.to/48kpsn" target="_blank"><button className={`w-[114.5px] h-[38px] border-[1px] ${isStednjaPage ? "border-[white] text-[white]" : "border-black text-black"} border-[white] rounded-[50px] leading-[14px] text-[14px]`}>Otvori račun</button></a>
                    </div>
                    <div className="flex items-center justify-center gap-[3px]">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.66992 0.807617C3.35792 0.807617 0.669922 3.49562 0.669922 6.80762C0.669922 10.1196 3.35792 12.8076 6.66992 12.8076C9.98192 12.8076 12.6699 10.1196 12.6699 6.80762C12.6699 3.49562 9.98192 0.807617 6.66992 0.807617ZM7.26992 9.80762H6.06992V6.20762H7.26992V9.80762ZM7.26992 5.00762H6.06992V3.80762H7.26992V5.00762Z" fill="#788497" />
                      </svg>

                      <p className="text-[8px] leading-[12.8px] text-[#788497] pt-[1.5px]">Ne investiraj novac koji ne možeš priuštiti.</p>
                    </div>

                  </div>
                </div>

                <div className="w-[335px] mt-[32px] h-[687px] flex flex-col">
                  <div className="h-[328px] w-full">
                    <img src={forexProd} alt="Forex" className="w-full h-full object-cover" />
                  </div>
                  <div className="p-[24px] w-full test-start" style={{ boxShadow: '0px 4px 8px 0px #B1B1B14D' }}>
                    <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                      <p>Forex</p>
                    </div>
                    <div className={`pt-[64px] pb-[16px] ${isStednjaPage ? "text-white" : "text-black"}`}>
                      <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">Forex</p>
                      <p className="text-[14px] leading-[16.8px] font-normal">Bez komplikovanih procedura.</p>
                    </div>
                    <div className="flex items-start justify-start gap-[8px] mb-[48px]">
                      <a href="https://tfcapital.me/forex" target="_blank"><button className={`w-[114.5px] h-[38px] ${isStednjaPage && "!bg-white !text-black"} bg-[#000000] rounded-[50px] text-white text-[14px] leading-[14px]`}>Saznaj više</button></a>
                      <a href="https://my.tfcapital.me/register" target="_blank"><button className={`w-[114.5px] h-[38px] ${isStednjaPage && "!border-white !text-white"} border-[1px] border-[#000000] rounded-[50px] text-[#000000] leading-[14px] text-[14px]`}>Otvori račun</button></a>
                    </div>
                    <div className="flex items-center justify-center gap-[3px]">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.66992 0.807617C3.35792 0.807617 0.669922 3.49562 0.669922 6.80762C0.669922 10.1196 3.35792 12.8076 6.66992 12.8076C9.98192 12.8076 12.6699 10.1196 12.6699 6.80762C12.6699 3.49562 9.98192 0.807617 6.66992 0.807617ZM7.26992 9.80762H6.06992V6.20762H7.26992V9.80762ZM7.26992 5.00762H6.06992V3.80762H7.26992V5.00762Z" fill="#788497" />
                      </svg>

                      <p className="text-[8px] leading-[12.8px] text-[#788497] pt-[1.5px]">Ne investiraj novac koji ne možeš priuštiti.</p>
                    </div>

                  </div>
                </div>


                <div className="w-[335px] mt-[32px] h-[687px] flex flex-col mb-[50px]">
                  <div className="h-[328px] w-full">
                    <img src={mnseProd} alt="MNSE" className="w-full h-full object-cover" />
                  </div>
                  <div className="p-[24px] w-full test-start" style={{ boxShadow: '0px 4px 8px 0px #B1B1B14D' }}>
                    <div className="py-[8px] px-[16px] rounded-[18px] text-[#E5EAFF] bg-[#262E3D] w-fit text-[12px] leading-[12px]">
                      <p>MNSE</p>
                    </div>
                    <div className={`pt-[64px] pb-[16px] ${isStednjaPage ? "text-white" : "text-black"}`}>
                      <p className="font-semibold text-[28px] leading-[33px] pb-[11px]">Montenegroberza</p>
                      <p className="text-[14px] leading-[16.8px] font-normal">Bez komplikovanih procedura.</p>
                    </div>
                    <div className="flex items-start justify-start gap-[8px] mb-[48px]">
                      <a href="https://tfcapital.me/montenegroberze" target="_blank"><button className="w-[114.5px] h-[38px] bg-[#148480] rounded-[50px] text-white text-[14px] leading-[14px]">Saznaj više</button></a>
                      <a href="https://tfcapital.me/montenegroberze#montenegro-form" target="_blank"><button className={`w-[114.5px] h-[38px] ${isStednjaPage && "!border-white !text-white"} border-[1px] border-[#000000] rounded-[50px] text-[#000000] leading-[14px] text-[14px]`}>Otvori račun</button></a>
                    </div>
                    <div className="flex items-center justify-center gap-[3px]">
                      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.66992 0.807617C3.35792 0.807617 0.669922 3.49562 0.669922 6.80762C0.669922 10.1196 3.35792 12.8076 6.66992 12.8076C9.98192 12.8076 12.6699 10.1196 12.6699 6.80762C12.6699 3.49562 9.98192 0.807617 6.66992 0.807617ZM7.26992 9.80762H6.06992V6.20762H7.26992V9.80762ZM7.26992 5.00762H6.06992V3.80762H7.26992V5.00762Z" fill="#788497" />
                      </svg>

                      <p className="text-[8px] leading-[12.8px] text-[#788497] pt-[1.5px]">Ne investiraj novac koji ne možeš priuštiti.</p>
                    </div>

                  </div>
                </div>

              </div>
            </div>

            <NavLink
              className={({ isActive }) =>
                isActive ? "font-medium text-[#3E6BE4] py-5 flex items-center justify-between text-[16px]  border-b-[1px] border-[#CCD0D7] px-[16px]" : "px-[16px] py-5 flex items-center justify-between  border-b-[1px] border-[#CCD0D7] text-[#1A1A1A]"
              }
              style={{ color: `${isStednjaPage ? "white" : "black"}` }}
              to="/news?filter=all"
              onClick={() => setIsMenuOpened(false)}
            >
              {t("news")}
              <ArrowMobile />
            </NavLink>

            {/* <NavLink
            className={({ isActive }) =>
              isActive ? "font-medium text-[#3E6BE4] py-5" : "py-5"
            }
            to="/about"
            onClick={() => setIsMenuOpened(false)}
          >
            {t("whyTf")}
          </NavLink> */}

            <NavLink
              className={({ isActive }) =>
                isActive ? "font-medium text-gray py-5 flex items-center justify-between text-[16px] border-b-[1px] border-[#CCD0D7] px-[16px]" : "px-[16px] py-5 flex items-center justify-between border-b-[1px] border-[#CCD0D7] text-[#1A1A1A]"
              }
              aria-disabled
              style={{ color: `${isStednjaPage ? "white" : "black"}` }}
            // to="/education"
            // onClick={() => setIsMenuOpened(false)}
            >
              {t("education")}
              <ArrowMobile />

            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? "font-medium text-[#3E6BE4] py-5 flex items-center justify-between text-[16px] border-b-[1px] border-[#CCD0D7] px-[16px]" : "px-[16px] py-5 flex items-center justify-between border-b-[1px] border-[#CCD0D7] text-[#1A1A1A]"
              }
              style={{ color: `${isStednjaPage ? "white" : "black"}` }}
              to="/about"
              onClick={() => setIsMenuOpened(false)}
            >
              {t("about")}
              <ArrowMobile />
            </NavLink>
            <div
              className="flex items-center justify-end pr-[24px] mt-[183px]"
            >
              <LanguageSwitcher />

            </div>
          </div>
        </div>
      </div >
    </div>
  );
}

export default Header;
