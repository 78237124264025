import React from 'react';
import ContactForm from '../components/ContactSectionDownload/ContactForm';

const DocumentDownload = () => {
    const data = [
        {
            title: "Investicioni vodič:",
            package: [
                {
                    title: "Zašto je sada pravi momenat da kreneš sa investiranjem?",
                    text: "Iz našeg invesitcionog vodiča saznaćeš zašto je tajming za početak investiranja važan. Prilike dolaze i proalze svakog dana, a najvažnije je početi sada i iskoristiti potencijal dugoročnog investiranja. Saznaj kako!"
                },
                {
                    title: "Kako otpočeti sa investiranjem?",
                    text: "Zapamti da je svaki uspješni investitor nekada bio početnik. Ne dozvoli da te neznanje uspori, već saznaj kako da započneš i iskoristiš investicione prilke koje su pred tobom. "
                },
                {
                    title: "Savjeti za početnike",
                    text: "Savjeti su ključna stvar ukoliko želiš da napreduješ. Uz naše savjete započni investiranje na pravi način i izbjegni zamke koje te mogu smesti da investiraš onako kako treba."
                },
            ]
        },
        {
            title: "10 ETF-ova koje treba pratiti:",
            package: [
                {
                    title: "Zašto treba pratiti ETF-ove?",
                    text: "Saznaj zbog čega su ETF-ovi (Exchange-Traded Funds) temelj dugoročnog investiranja.<br /> Dok drugi već koriste njihove prednosti za stabilan rast kapitala, ne dozvoli da propustiš priliku i optimizuješ svoj portfolio na pravi način!"
                },
                {
                    title: "Kako odabrati pravi ETF?",
                    text: "Saznaj kako da odabereš ETF-ove sa najboljim potencijalom i iskoristiš ih u rast tvoga kapitala. Otkrij ključne faktore ETF-ova koji će ti pomoći da doneseš najbolju odluku. "
                },
                {
                    title: "10 ETF-ova koje moraš pratiti",
                    text: "Ovi fondovi pokrivaju širok spektar industrija i tržišta. Najbolji investitori na svijetu prate upravo ovih 10 ETF-ova koje smo ti pripremili u našem dokumentu. Zato ne dozvoli sebi da propustiš priliku i saznaš koji će te fondovi pretvoriti u dobrog investitora."
                },
            ]
        },
    ];

    return (
        <div className='bg-[white] w-full h-auto mt-[-95px] pt-[110px]  text-white'>
            <div className='relative flex flex-col items-center lg:flex-row lg:items-start lg:justify-between lg:pb-[80px] text-black h-auto max-w-[1200px] mx-auto px-[16px]'>
                <div className='w-fit lg:pt-[98px]'>
                    <h1 className='font-bold text-[44px] lg:text-[56px] lg:text-start lg:leading-[61.6px] leading-[48.4px] text-center'>Preuzmi.<br />
                        Primijeni.<br />
                        <span className='bg-gradient-to-r from-[#3E6BE4] to-[#84B1FF] text-transparent bg-clip-text inline-block'>Investiraj.</span></h1>
                    <p className='text-[18px] leading-[25.2px] lg:text-start text-black text-center pt-[20px] max-w-[375px] mx-auto'>
                        Nauči principe koji prave razliku između početnika i ozbiljnih investitora.
                    </p>
                </div>

                <div className='pt-[48px] pb-[64px] lg:mt-[40px] lg:pt-[32px] lg:bg-[#F6F8FC] lg:w-[589px] w-full lg:p-[32px] lg:rounded-[16px] lg:border-[1px] lg:border-[#3E6BE4] lg:shadow-[0px_0px_8px_0px_#3E6BE480]'>
                    <h3 className='mx-auto text-center lg:text-start pb-[32px] font-semibold text-[24px] w-full leading-[33.6px] text-black'>
                        Preuzmi dokumentaciju:
                    </h3>
                    <div className='lg:flex items-center justify-center lg:justify-start lg:max-w-[600px] max-w-full flex-shrink-1'>
                        <ContactForm showTitle={false} />
                    </div>
                </div>
            </div>

            <div className='bg-[#F6F8FC]'>
                <div className='relative text-black h-auto max-w-[1200px] mx-auto px-[16px] py-[64px] lg:py-[100px]'>
                    <h2 className='font-bold text-[32px] leading-[38.4px] text-center lg:hidden block'>Šta dobijaš u <br /> dokumentaciji?</h2>
                    <h2 className='font-bold text-[32px] leading-[38.4px] text-center hidden lg:block'>Šta dobijaš u dokumentaciji?</h2>
                    <div className='lg:flex items-start justify-between'>
                        {
                            data.map((section) => {
                                return (
                                    <div className='pt-[48px] lg:pt-[64px] lg:max-w-[464px]'>
                                        <p className='text-[18px] leading-[21.6px] pb-[32px] font-bold'>{section.title}</p>
                                        <div className='flex flex-col gap-[32px] items-start justify-start ml-[16px]'>
                                            {
                                                section.package.map((packageData) => {
                                                    return (
                                                        <ul className='!list-disc'>
                                                            <li className='!list-disc '>
                                                                <p className='font-bold text-[16px] leading-[19.2px] pb-[16px]'>{packageData.title}</p>
                                                                <p className='text-[14px] leading-[19.6px]' dangerouslySetInnerHTML={{ __html: packageData.text }}></p>
                                                            </li>
                                                        </ul>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div >
    );
};

export default DocumentDownload;