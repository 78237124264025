import React from "react";
import { useLocation } from "react-router-dom";

const TitleSection = ({
  title,
  subtitle,
  titleColor = "text-black",
  subtitleColor = "text-[#A6A6A6]",
  showTitleSecond = false,
}) => {
  const location = useLocation();
  const isStednjaPage = location.pathname.includes('/stednja');

  return (
    <div className="flex flex-col gap-[20px]">
      {!showTitleSecond ? (
        <>
          <h2
            className={`${isStednjaPage && "text-white"} font-medium lg:text-[30px] lg:leading-[36px] text-[32px] leading-[38px] text-center ${titleColor}`}
          >
            {title}
          </h2>
          {subtitle && (
            <p
              className={`font-bold text-base text-[16px] leading-[20px] text-center ${subtitleColor}`}
            >
              {subtitle}
            </p>
          )}
        </>
      ) : (
        <>
          {subtitle && (
            <p
              className={`font-bold text-base leading-[20px] text-center ${subtitleColor}`}
            >
              {subtitle}
            </p>
          )}
          <h2
            className={`font-medium lg:text-[30px] lg:leading-[36px] text-[32px] leading-[38px] text-center ${titleColor}`}
          >
            {title}
          </h2>
        </>
      )}
    </div>
  );
};

export default TitleSection;
