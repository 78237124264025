import { httpClient } from "../httpClient";

export const submitForm = async (payload) => {
  const response = await httpClient.post("/contact", payload);
  return response;
};

export const sendRequest = async (payload) => {
  const response = await httpClient.post("/contact-stednja", payload);
  return response;
};

export const sendUserData = async (payload) => {
  const response = await httpClient.post("/contact-pdfdownload", payload);
  return response;
};
