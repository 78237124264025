import * as Yup from "yup";
import { useState, useCallback, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import MontenegroSVG from "../../assets/Montenegro.svg";
import IconSVG from "../../assets/icon.svg";
import checkSuccess from "../../assets/check-success.svg";
import checkError from "../../assets/check-error.svg";
import { Check, X } from 'lucide-react';

import TitleSection from "../TitleSection/TitleSection";
import { useMutation } from "@tanstack/react-query";
import { sendRequest, submitForm } from "../../api/repositories/contactRepository";

const states = [
  { id: 1, name: 'CG (Crna Gora)', code: '+382', flag: '🇲🇪' },
  { id: 2, name: 'SRB (Srbija)', code: '+381', flag: '🇷🇸' },
  { id: 3, name: 'BIH (Bosna i Hercegovina)', code: '+387', flag: '🇧🇦' },
  { id: 4, name: 'KOSOVO', code: '+383', flag: '🇽🇰' },
  { id: 5, name: 'ALBANIJA', code: '+355', flag: '🇦🇱' },
  { id: 6, name: 'MAKEDONIJA', code: '+389', flag: '🇲🇰' },
];

function ContactSectionNew(showTitle = false) {
  const { t } = useTranslation("contact");
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const location = useLocation();
  const isStednjaPage = location.pathname.includes('/stednja');
  const isDocuDownloadPage = location.pathname.includes('/document-download');
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [selectedState, setSelectedState] = useState(states[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [pdfSelected, setPdfSelected] = useState(0);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("contactValidation.name")),
    surname: Yup.string().required(t("contactValidation.name")),
    email: Yup.string()
      .email(t("contactValidation.email"))
      .required(t("contactValidation.emailRequired")),
    number: Yup.number().required(t("contactValidation.name")),
    message: Yup.string().when([], {
      is: () => !isStednjaPage && !isDocuDownloadPage, // Only required if isStednjaPage is false
      then: (schema) => schema.required(t("contactValidation.message")),
      otherwise: (schema) => schema.notRequired(),
    })
  });

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    number: "",
    message: "",
  };

  const [seconds, setSeconds] = useState(5);

  const { mutate, status } = useMutation({
    mutationFn: isStednjaPage || isDocuDownloadPage ? sendRequest : submitForm,
    onSuccess: () => {
      console.log("Uspjesno poslato");
      setSeconds(5);
    },
    onError: (error) => {
      throw new Error('Something went wrong!');
    },
  });

  useEffect(() => {
    if (status === 'success' && seconds > 0) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = "auto"; // Enable scrolling
    }

    // Cleanup on unmount
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [seconds > 0]);

  const verifyRecaptchaCallback = useCallback((token) => {
    setToken(token);
  }, []);

  useEffect(() => {
    const executeRecaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(siteKey, { action: 'submit' })
            .then(captcha => {
              verifyRecaptchaCallback(captcha);
            });
        });
      } else {
        // Wait for reCAPTCHA to be defined
        setTimeout(executeRecaptcha, 100);
      }
    };

    executeRecaptcha();
  }, [siteKey, verifyRecaptchaCallback, refreshReCaptcha]);

  const handleSubmit = (values, { resetForm }) => {
    const payload = {
      first_name: values.name,
      last_name: values.surname,
      email: values.email,
      phone: `${selectedState.code} ${values.number}`,
      message: values.message,
      page_origin: location.pathname.slice(1),
      captchaToken: token
    };
    mutate(payload);
    resetForm();
  };

  useEffect(() => {
    if (seconds <= 0) return;
    const timer = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  useEffect(() => {
    setTimeout(() => {
      setRefreshReCaptcha(r => !r);
    }, 120000);
  }, [token]);

  return (
    <div className="flex flex-col relative items-center gap-[60px] pt-[50px] pb-[75px]">
      <div className={`px-[60px] ${showTitle && "hidden"}`}>
        <TitleSection title={t("contactForm.title")} />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
          <Form className="flex flex-col items-center gap-8 lg:max-w-[906px] md:max-w-[500px] w-full">
            <div className={`flex justify-between lg:flex-row flex-col gap-5 items-center w-full ${isStednjaPage && "!justify-center"}`}>
              <div className="flex flex-wrap justify-center lg:flex-col  flex-col gap-6 lg:w-[390px] w-full">
                <div className="w-full flex justify-center lg:flex-row flex-col  gap-[21px]">
                  <div className="flex flex-col gap-[10px]">
                    <label className={`text-[10px] text-[#788497] font-bold leading-[12px] ${isStednjaPage && "text-[white]"}`}>
                      {t("contactForm.nameLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="name"
                        id="name"
                        placeholder="Ivan"
                        className={`${isStednjaPage && "!bg-[#373743] !text-[#A6A6A6] !border-[#495E93] "} border-[#D9D9D9] border-[1px] rounded-[8px] lg:w-[184px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3 `}
                        type="text"
                      />
                      <div>
                        <img
                          className={`absolute top-[13px] right-[13px] rounded-[10px]`}
                          src={
                            touched.name
                              ? errors.name
                                ? checkError
                                : checkSuccess
                              : IconSVG
                          }
                          alt="Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label className={`text-[10px] text-[#788497] font-bold leading-[12px] ${isStednjaPage && "text-[white]"}`}>
                      {t("contactForm.surnameLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="surname"
                        id="surname"
                        placeholder="Ivanović"
                        className={`${isStednjaPage && "!bg-[#373743] !text-[#A6A6A6] !border-[#495E93] "} border-[#D9D9D9] border-[1px] rounded-[8px] lg:w-[184px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3`}
                        type="text"
                      />
                      <img
                        className={`absolute top-[13px] right-[13px] rounded-[10px]`}
                        src={
                          touched.surname
                            ? errors.surname
                              ? checkError
                              : checkSuccess
                            : IconSVG
                        }
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-2">
                  <label className={`text-[10px] text-[#788497] font-bold leading-[12px] ${isStednjaPage && "text-[white]"}`}>
                    {t("contactForm.emailLabel")}
                  </label>
                  <div className="relative w-full">
                    <Field
                      name="email"
                      id="email"
                      placeholder="ivan.ivanovic@gmail.com"

                      className={`${isStednjaPage && "!bg-[#373743] !text-[#A6A6A6] !border-[#495E93] "} border-[#D9D9D9] border-[1px] rounded-[8px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3`}
                      type="email"
                    />
                    <img
                      className={`absolute top-[13px] right-[13px] rounded-[10px] `}
                      src={
                        touched.email
                          ? errors.email
                            ? checkError
                            : checkSuccess
                          : IconSVG
                      }
                      alt="Icon"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-[10px]">
                  <label className={`text-[10px] font-bold leading-[12px] ${isStednjaPage ? "text-white" : "text-[#788497]"}`}>
                    Broj telefona*
                  </label>
                  <div className="flex w-full">
                    <div
                      onClick={() => setIsOpen(!isOpen)}
                      className={`bg-[#F4F7FF] border-[1px] rounded-[8px] px-[12px] py-[5px] mr-1 ${isStednjaPage && "!bg-[#373743] !text-[#A6A6A6] !border-[#495E93] "} border-[#D9D9D9]`}
                    >
                      <span className="text-lg">{selectedState.flag}</span>
                    </div>
                    <div className="relative w-full">
                      <span className="px-3 py-[13.5px] text-[12px] text-[#CCD0D7] leading-[14px] absolute">
                        {selectedState.code}
                      </span>
                      <Field
                        name="number"
                        id="number"
                        className={`${isStednjaPage && "!bg-[#373743] !text-[#A6A6A6] !border-[#495E93] "} border-[#D9D9D9] border-[1px] rounded-[8px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-[55px]`}
                        type="number"
                      />
                    </div>
                  </div>
                  {isOpen && (
                    <ul className="absolute z-[99999] h-[200px] overflow-y-auto mt-4 w-fit border border-[#495E93] rounded-lg bg-white shadow-lg">
                      {states.map(state => (
                        <li
                          key={state.id}
                          onClick={() => {
                            setSelectedState(state);
                            setIsOpen(false);
                          }}
                          className="flex items-center w-fit gap-2 px-4 py-2  cursor-pointer hover:bg-gray-100"
                        >
                          <span className="text-lg text-black">{state.flag}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              {!isStednjaPage && !isDocuDownloadPage && <div className="2xl:w-[596px] lg:w-[546px]  w-full h-[231px] gap-[10px] flex flex-col ">
                <label className={`text-[10px] text-[#788497] font-bold leading-[12px] ${isStednjaPage && "text-[white] hidden"}`}>
                  {t("contactForm.question")}
                </label>
                <div className="relative w-full h-full ">
                  <Field
                    as="textarea"
                    id="message"
                    name="message"
                    className={`${isStednjaPage ? "!bg-[#373743] !text-[#A6A6A6] border-[#495E93]" : "bg-[#F4F7FF] border-[#D9D9D9]"} border-[1px] rounded-[8px] w-full h-full text-[12px] text-[#A6A6A6] leading-[19px] p-3`}
                  ></Field>
                  <img
                    className={`absolute bottom-[13px] right-[13px] rounded-[10px]`}
                    src={
                      touched.message
                        ? errors.message
                          ? checkError
                          : checkSuccess
                        : IconSVG
                    }
                    alt="Icon"
                  />
                </div>
              </div>
              }
            </div>
            {isDocuDownloadPage && <div className="mr-auto flex flex-col items-start justify-start gap-[13px]">
              <p className="leading-[12.78px] text-[18px] text-[#788497] pb-[14px]">Odaberi dokumentaciju*</p>
              <div className="flex items-center justify-start gap-[8px]" onClick={() => setPdfSelected(0)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke={`${pdfSelected === 0 ? "#3E6BE4" : "#D9D9D9"}`} stroke-width="1.5" />
                  {pdfSelected === 0 && <path d="M5 10.2368L9 14.5L15 5.5" stroke="#3E6BE4" stroke-width="1.5" stroke-linecap="round" />}
                </svg>
                <p className="leading-[14px] text-[14px] text-[#788497]">Investicioni vodič (PDF)</p>
              </div>
              <div className="flex items-center justify-start gap-[8px]" onClick={() => setPdfSelected(1)}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke={`${pdfSelected === 1 ? "#3E6BE4" : "#D9D9D9"}`} stroke-width="1.5" />
                  {pdfSelected === 1 && <path d="M5 10.2368L9 14.5L15 5.5" stroke="#3E6BE4" stroke-width="1.5" stroke-linecap="round" />}
                </svg>
                <p className="leading-[14px] text-[14px] text-[#788497]">10 ETF-ova koje treba pratiti (PDF)</p>
              </div>
            </div>}
            <button
              disabled={status === "pending"}
              type="submit"
              className={` button-big !text-[white] lg:max-w-[150px] bg-[#3E6BE4] hover:bg-[#335dcc] !rounded-full !w-full`}
            >
              {status === "pending" ? (
                <div className="border-t-transparent mx-auto border-solid animate-spin rounded-full border-blue-500 border-2 h-3 w-3"></div>
              ) : (
                t("contactForm.buttonText")
              )}
            </button>
            {/* {isStednjaPage && ( */}
            {isStednjaPage && status === "success" && seconds > 0 && (
              <div className="fixed z-[999999999999] top-0 left-0 bg-[#00000080] w-full h-screen backdrop-blur-[50px]">
                <div className="bg-[#12172CBF] flex flex-col items-center justify-center w-[320px] rounded-[14px] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 p-[16px]" style={{ boxShadow: '0px 2px 16px 0px #00D3FF29' }}>
                  <Check color="#00D3FF" size={28} className='flex-shrink-0 mt-[38px] lg:mt-0' />
                  <p className="font-semibold text-[18px] leading-[22px] pt-[4px] pb-[8px]">Hvala što ste nas kontaktirali!</p>
                  <p className="text-[12px] text-center leading-[16px]">
                    Vaša poruka je uspješno primljena, jedan od naših TF Capital operatera će vam se uskoro javiti.</p>
                  <button onClick={() => setSeconds(0)} className="bg-[#3E6BE4] rounded-full w-[150px] h-[40px] mt-[16px]">U redu</button>
                  <p className="text-[10px] pt-[12px] text-[#A6A6A6]">Ovaj prozor će se zatvoriti za {seconds}</p>
                </div>
              </div>
            )}
            {!isStednjaPage && (status === "success" || status === "error") && (
              <p
                className={`text-[14px] leading-[19px] ${status === "success" ? "text-green-500" : "text-red-500"}`}
              >
                {status === "success" && t("contactForm.successMsg")}
                {status === "error" && t("contactForm.errorMsg")}
              </p>
            )}


            {
              isStednjaPage && <div className="flex mt-[-10px] items-start justify-center gap-[11px]">
                <svg className="flex-shrink-0" width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 0.804688C7.21997 0.804687 5.47991 1.33253 3.99987 2.32146C2.51983 3.31039 1.36628 4.716 0.685088 6.36054C0.00389957 8.00507 -0.17433 9.81467 0.172937 11.5605C0.520204 13.3063 1.37737 14.91 2.63604 16.1686C3.89472 17.4273 5.49836 18.2845 7.24419 18.6318C8.99002 18.979 10.7996 18.8008 12.4442 18.1196C14.0887 17.4384 15.4943 16.2849 16.4832 14.8048C17.4722 13.3248 18 11.5847 18 9.80469C18 7.41774 17.0518 5.12855 15.364 3.44073C13.6761 1.7529 11.387 0.804688 9 0.804688ZM9 17.3047C7.51664 17.3047 6.0666 16.8648 4.83323 16.0407C3.59986 15.2166 2.63856 14.0453 2.07091 12.6748C1.50325 11.3044 1.35473 9.79637 1.64411 8.34151C1.9335 6.88665 2.64781 5.55028 3.6967 4.50139C4.7456 3.45249 6.08197 2.73819 7.53683 2.4488C8.99168 2.15941 10.4997 2.30793 11.8701 2.87559C13.2406 3.44325 14.4119 4.40454 15.236 5.63791C16.0601 6.87128 16.5 8.32133 16.5 9.80469C16.5 11.7938 15.7098 13.7015 14.3033 15.108C12.8968 16.5145 10.9891 17.3047 9 17.3047Z" fill="#A6A6A6" />
                  <path d="M8.99938 8.07967C8.80047 8.07967 8.6097 8.15869 8.46905 8.29934C8.3284 8.43999 8.24938 8.63076 8.24938 8.82967V13.9297C8.24938 14.1286 8.3284 14.3193 8.46905 14.46C8.6097 14.6007 8.80047 14.6797 8.99938 14.6797C9.19829 14.6797 9.38906 14.6007 9.52971 14.46C9.67036 14.3193 9.74938 14.1286 9.74938 13.9297V8.82967C9.74938 8.63076 9.67036 8.43999 9.52971 8.29934C9.38906 8.15869 9.19829 8.07967 8.99938 8.07967ZM8.99938 5.00467C8.79634 5.0123 8.60394 5.09738 8.46167 5.24244C8.3194 5.3875 8.23807 5.58152 8.23438 5.78467V5.90467C8.23402 6.00252 8.25404 6.09938 8.29317 6.18907C8.33229 6.27877 8.38965 6.35934 8.46161 6.42565C8.53357 6.49197 8.61854 6.54258 8.71113 6.57426C8.80371 6.60594 8.90188 6.618 8.99938 6.60967C9.19592 6.60224 9.3824 6.52084 9.52148 6.38177C9.66055 6.24269 9.74196 6.05621 9.74938 5.85967V5.67967C9.74997 5.58435 9.73036 5.48998 9.69185 5.40278C9.65334 5.31558 9.5968 5.23753 9.52594 5.17376C9.45509 5.11 9.37153 5.06196 9.28077 5.03281C9.19002 5.00367 9.09411 4.99408 8.99938 5.00467Z" fill="#A6A6A6" />
                </svg>

                <p className="text-[10px] lg:text-[14px] lg:pt-[2px] max-w-[311px] leading-[20px] text-[#A6A6A6]">Štednja je u USD dolarima</p>
              </div>
            }
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactSectionNew;
