// src/components/Footer.js

import React from 'react';
import pdf1 from '../../assets/regulativa/AML_Program_ENG.pdf';
import pdf1_me from '../../assets/regulativa/regulativaMe/AMLProgramMe.pdf';
import pdf2 from '../../assets/regulativa/Conflict_of_interest_management_policy.pdf';
import pdf2_me from '../../assets/regulativa/regulativaMe/Politikaupravljanjasukobominteresa.pdf';
import pdf3 from '../../assets/regulativa/Financial_Instrument_Information.pdf';
import pdf3_me from '../../assets/regulativa/regulativaMe/Informacijeofinansijskiminstrumentima.pdf';
import pdf4 from '../../assets/regulativa/regulativaNew/General_terms_of_business_of_the_investment_company.pdf';
import pdf4_me from '../../assets/regulativa/regulativaMe/Opstapravilaposlovanja.pdf';
import pdf5 from '../../assets/regulativa/regulativaNew/Policy_of_Investor_classification.pdf';
import pdf5_me from '../../assets/regulativa/regulativaMe/Politikarazvrstavanjaklijenata.pdf';
import pdf6 from '../../assets/regulativa/Pricelist.pdf';
import pdf6_me from '../../assets/regulativa/regulativaMe/CjenovnikUslugaNew.pdf';
import pdf7 from '../../assets/regulativa/PrivacyPolicy.pdf';
import pdf7_me from '../../assets/regulativa/regulativaMe/Izjavaoprivatnostipodataka.pdf';
import pdf8 from '../../assets/regulativa/Questionnaire_for_Sorting_a_client_as_a_small_investor.pdf';
import pdf8_me from '../../assets/regulativa/regulativaMe/Upitnikzarazvrstavanjeklijenata.pdf';
import pdf9 from '../../assets/regulativa/Risk_disclosure_statement_on_complex_products_2023-12.pdf';
import pdf9_me from '../../assets/regulativa/regulativaMe/Izjavaoriziku.pdf';
import pdf10 from '../../assets/regulativa/RulebookoncomplaintsofInvestmentcompany.pdf';
import pdf10_me from '../../assets/regulativa/regulativaMe/Pravilnikoprituzbama.pdf';
import pdf11 from '../../assets/regulativa/Rulebook_on_identifying_measuring_assessing_and_managing_risks.pdf';
import pdf11_me from '../../assets/regulativa/regulativaMe/Pravilnikoidentifikovanjurizika.pdf';
import pdf12 from '../../assets/regulativa/regulativaNew/Rulebook_on_information_provided_specifically_to_small_clients.pdf';
import pdf12_me from '../../assets/regulativa/regulativaMe/Pravilnikoinformacijamakojeseposebnopruzajumalimklijentima.pdf';
import pdf13 from '../../assets/regulativa/Statement_of_Clients_funds.pdf';
import pdf13_me from '../../assets/regulativa/regulativaMe/Izjavaonacinukoriscenjanovcanihsredstavaklijenata.pdf';
import pdf14 from '../../assets/regulativa/Suitability_statement.pdf';
import pdf14_me from '../../assets/regulativa/regulativaMe/Izjavaoprimjerenosti.pdf';
import pdf15 from '../../assets/regulativa/OrderExecutionPolicy.pdf';
import pdf15_me from '../../assets/regulativa/regulativaMe/Politikaizvršenjanaloga.pdf';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';

const RegulationModal = ({ setModal }) => {
    const { t } = useTranslation("footer");
    const locale = useTranslation().i18n.language;
    console.log(locale);

    const pdfFiles = [
        { name: t("footer.pdfFiles.aml"), link: locale === 'en' ? pdf1 : pdf1_me },
        { name: t("footer.pdfFiles.conflictOfInterests"), link: locale === 'en' ? pdf2 : pdf2_me },
        { name: t("footer.pdfFiles.financialInstrument"), link: locale === 'en' ? pdf3 : pdf3_me },
        { name: t("footer.pdfFiles.generalTerms"), link: locale === 'en' ? pdf4 : pdf4_me },
        { name: t("footer.pdfFiles.investorClassification"), link: locale === 'en' ? pdf5 : pdf5_me },
        { name: t("footer.pdfFiles.priceList"), link: locale === 'en' ? pdf6 : pdf6_me },
        { name: t("footer.pdfFiles.privacyPolicy"), link: locale === 'en' ? pdf7 : pdf7_me },
        { name: t("footer.pdfFiles.clientQuestionnaire"), link: locale === 'en' ? pdf8 : pdf8_me },
        { name: t("footer.pdfFiles.riskDisclosure"), link: locale === 'en' ? pdf9 : pdf9_me },
        { name: t("footer.pdfFiles.complaintsRulebook"), link: locale === 'en' ? pdf10 : pdf10_me },
        { name: t("footer.pdfFiles.riskManagementRulebook"), link: locale === 'en' ? pdf11 : pdf11_me },
        { name: t("footer.pdfFiles.smallClientsInfoRulebook"), link: locale === 'en' ? pdf12 : pdf12_me },
        { name: t("footer.pdfFiles.clientFundsStatement"), link: locale === 'en' ? pdf13 : pdf13_me },
        { name: t("footer.pdfFiles.suitabilityStatement"), link: locale === 'en' ? pdf14 : pdf14_me },
        { name: t("footer.pdfFiles.orderExecutionPolicy"), link: locale === 'en' ? pdf15 : pdf15_me },
    ];


    return (
        <div className='w-full h-full bg-white/50 backdrop-filter    backdrop-blur-sm  transition-colors duration-500 ease-in-out fixed top-0 left-0 z-[999]'>
            <div className="text-center fixed bottom-[50%] translate-y-2/4 w-full px-3 lg:w-[620px] h-[530px] bg-white right-[50%] translate-x-2/4 shadow-xl">
                <h1 className='my-5 text-align font-medium text-[24px] text-center'>{t("footer.modalTitle")}</h1>
                <div className='flex flex-col items-start justify-start mx-auto w-fit h-[350px] scroll gap-5 overflow-x-auto'>
                    {pdfFiles.map((pdf, index) => (
                        <a key={index} href={pdf.link} target="_blank" rel="noopener noreferrer" className='bg-[#f2f2f2] hover:bg-[#335dcc] hover:text-white w-full p-3'>
                            {pdf.name}
                        </a>
                    ))}
                </div>
                <button onClick={() => setModal(false)} className='w-1/2 bg-[#3e6be4] p-3 m-5 text-white'>{t('footer.exit')}</button>
            </div>
        </div>
    );
};

export default RegulationModal;
