import * as Yup from "yup";
import { useState, useCallback, useEffect } from "react";
import { Formik, Field, Form } from "formik";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import IconSVG from "../../assets/icon.svg";
import checkSuccess from "../../assets/check-success.svg";
import checkError from "../../assets/check-error.svg";
import { Check, X, FileDown, Info } from 'lucide-react';
import pdf from '../../assets/Vodic-za-investitore.pdf';
import pdfFile2 from '../../assets/deset-etf-ova-za-koje-svaki-investitor-treba-da-zna.pdf';
import TitleSection from "../TitleSection/TitleSection";
import { useMutation } from "@tanstack/react-query";
import { sendUserData } from "../../api/repositories/contactRepository";
const states = [
  { id: 1, name: 'CG (Crna Gora)', code: '+382', flag: '🇲🇪' },
  { id: 2, name: 'SRB (Srbija)', code: '+381', flag: '🇷🇸' },
  { id: 3, name: 'BIH (Bosna i Hercegovina)', code: '+387', flag: '🇧🇦' },
  { id: 4, name: 'KOSOVO', code: '+383', flag: '🇽🇰' },
  { id: 5, name: 'ALBANIJA', code: '+355', flag: '🇦🇱' },
  { id: 6, name: 'MAKEDONIJA', code: '+389', flag: '🇲🇰' },
];

function ContactSectionNew(showTitle = false) {
  const { t } = useTranslation("contact");
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  const location = useLocation();
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
  const [selectedState, setSelectedState] = useState(states[0]);
  const [isOpen, setIsOpen] = useState(false);
  const [pdf1, setPdf1Selected] = useState(false);
  const [pdf2, setPdf2Selected] = useState(false);
  const [pdfError, setPdfError] = useState(false);

  const handleDownload = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t("contactValidation.name")),
    surname: Yup.string().required(t("contactValidation.name")),
    email: Yup.string()
      .email(t("contactValidation.email"))
      .required(t("contactValidation.emailRequired")),
    number: Yup.number().required(t("contactValidation.name")),
  });

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    number: "",
    pdf1: pdf1,
    pdf2: pdf2,
  };

  const [seconds, setSeconds] = useState(5);

  const { mutate, status } = useMutation({
    mutationFn: sendUserData,
    onSuccess: () => {
      // downloadFiles();
      // console.log("Uspjesno poslato");
      setSeconds(5);
    },
    onError: (error) => {
      throw new Error('Something went wrong!');
    },
  });

  useEffect(() => {
    if (status === 'success' && seconds > 0) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [seconds > 0]);

  const verifyRecaptchaCallback = useCallback((token) => {
    setToken(token);
  }, []);

  // useEffect(() => {
  //   const executeRecaptcha = () => {
  //     if (window.grecaptcha) {
  //       window.grecaptcha.ready(() => {
  //         window.grecaptcha
  //           .execute(siteKey, { action: 'submit' })
  //           .then(captcha => {
  //             verifyRecaptchaCallback(captcha);
  //           });
  //       });
  //     } else {
  //       setTimeout(executeRecaptcha, 100);
  //     }
  //   };

  //   executeRecaptcha();
  // }, [siteKey, verifyRecaptchaCallback, refreshReCaptcha]);

  const handleSubmit = (values, { resetForm }) => {
    if (!pdf1 && !pdf2) {
      setPdfError(true);
      return false;
    }
    const payload = {
      first_name: values.name,
      last_name: values.surname,
      email: values.email,
      phone: `${selectedState.code} ${values.number}`,
      pdf1: pdf1,
      pdf2: pdf2,
      page_origin: location.pathname.slice(1),
      captchaToken: token
    };
    mutate(payload);
    resetForm();
  };

  useEffect(() => {
    if (seconds <= 0) return;
    const timer = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setRefreshReCaptcha(r => !r);
  //   }, 120000);
  // }, [token]);

  return (
    <div className="flex flex-col relative items-center gap-[60px]">
      <div className={`px-[60px] ${showTitle && "hidden"}`}>
        <TitleSection title={t("contactForm.title")} />
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, setFieldTouched, values, errors, touched }) => (
          <Form className="flex flex-col items-center justify-center gap-8 lg:max-w-[906px] md:max-w-[500px] w-full">
            <div className={`flex justify-between lg:flex-row flex-col gap-5 items-center w-full`}>
              <div className="flex flex-wrap justify-center lg:flex-col  flex-col gap-6 lg:w-[525px] w-full">
                <div className="w-full flex justify-center lg:flex-row flex-col  gap-[21px]">
                  <div className="flex flex-col gap-[10px]">
                    <label className={`text-[10px] text-[#788497] font-bold leading-[12px]`}>
                      {t("contactForm.nameLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="name"
                        id="name"
                        placeholder="Ivan"
                        className={`${errors.name ? "border-[#FF2828]" : "border-[#D9D9D9]"} border-[1px] rounded-[8px] w-full lg:w-[250.5px] h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3 `}
                        type="text"
                      />
                      <div>
                        <img
                          className={`absolute top-[13px] right-[13px] rounded-[10px]`}
                          src={
                            touched.name
                              ? errors.name
                                ? checkError
                                : checkSuccess
                              : IconSVG
                          }
                          alt="Icon"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[10px]">
                    <label className={`text-[10px] text-[#788497] font-bold leading-[12px]`}>
                      {t("contactForm.surnameLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="surname"
                        id="surname"
                        placeholder="Ivanović"
                        className={`${errors.surname ? "border-[#FF2828]" : "border-[#D9D9D9]"} border-[1px] rounded-[8px] w-full lg:w-[250.5px] h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3`}
                        type="text"
                      />
                      <img
                        className={`absolute top-[13px] right-[13px] rounded-[10px]`}
                        src={
                          touched.surname
                            ? errors.surname
                              ? checkError
                              : checkSuccess
                            : IconSVG
                        }
                        alt="Icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:flex-row items-center justify-center gap-[21px]">
                  <div className="flex flex-col gap-[10px] w-full">
                    <label className={`text-[10px] text-[#788497] font-bold leading-[12px]`}>
                      {t("contactForm.emailLabel")}
                    </label>
                    <div className="relative">
                      <Field
                        name="email"
                        id="email"
                        placeholder="ivan.ivanovic@gmail.com"

                        className={`${errors.email ? "border-[#FF2828]" : "border-[#D9D9D9]"} border-[1px] rounded-[8px] lg:w-[250.5px] w-full h-[40px] bg-[#F4F7FF] text-[12px] text-[#A6A6A6] leading-[14px] pl-3`}
                        type="email"
                      />
                      <img
                        className={`absolute top-[13px] right-[13px] rounded-[10px] `}
                        src={
                          touched.email
                            ? errors.email
                              ? checkError
                              : checkSuccess
                            : IconSVG
                        }
                        alt="Icon"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-[10px]">
                    <label className={`text-[10px] font-bold text-[#788497] leading-[12px] `}>
                      Broj telefona*
                    </label>
                    <div className="flex w-full lg:w-[250.5px]">
                      <div
                        onClick={() => setIsOpen(!isOpen)}
                        className={`bg-[#F4F7FF] border-[1px] rounded-[8px] px-[12px] py-[5px] mr-1 ${errors.number ? "border-[#FF2828]" : "border-[#D9D9D9]"}`}
                      >
                        <span className="text-lg">{selectedState.flag}</span>
                      </div>
                      <div className="relative w-full">
                        <span className="px-3 py-[13.5px] text-[12px] text-[#CCD0D7] leading-[14px] absolute">
                          {selectedState.code}
                        </span>
                        <Field
                          name="number"
                          id="number"
                          className={` ${errors.number ? "border-[#FF2828]" : "border-[#D9D9D9]"} relative z-[10] border-[1px] rounded-[8px] w-full h-[40px] bg-transparent text-[12px] text-[#A6A6A6] leading-[14px] pl-[55px]`}
                          type="number"
                        />
                      </div>
                    </div>
                    {isOpen && (
                      <ul className="absolute z-[99999] h-[200px] overflow-y-auto mt-4 w-fit border border-[#495E93] rounded-lg bg-white shadow-lg">
                        {states.map(state => (
                          <li
                            key={state.id}
                            onClick={() => {
                              setSelectedState(state);
                              setIsOpen(false);
                            }}
                            className="flex items-center w-fit gap-2 px-4 py-2  cursor-pointer hover:bg-gray-100"
                          >
                            <span className="text-lg text-black">{state.flag}</span>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>

            </div>
            <div className="w-full">
              <p className="leading-[12.78px] text-[18px] pb-[24px] text-[#788497]">Odaberi dokumentaciju*</p>

              <div className="mr-auto flex flex-col lg:flex-row items-start justify-start gap-[13px]">
                <div className={`flex flex-col items-between h-[100px] w-full lg:w-[254.4px] justify-between select-none cursor-pointer p-[16px] border-[1px] ${pdf1 ? "border-[#3E6BE4]" : "border-[#A6A6A6]"} rounded-[8px]`} onClick={() => setPdf1Selected(!pdf1)}>
                  <div className="flex items-start justify-between gap-[16px] ">
                    <FileDown color={pdf1 ? "#3E6BE4" : "#A6A6A6"} />
                    {pdf1 && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#3E6BE4" stroke-width="1.5" />
                      <path d="M5 10.2368L9 14.5L15 5.5" stroke="#3E6BE4" stroke-width="1.5" stroke-linecap="round" />
                    </svg>}
                  </div>
                  <p className={`leading-[14px] text-[14px] font-semibold ${pdf1 ? "text-[#3E6BE4]" : "text-[#000000]"}`}>Vodič za investitore</p>
                </div>
                <div className={`flex flex-col items-start justify-between w-full h-[100px] lg:w-[254.4px] select-none cursor-pointer p-[16px] border-[1px] ${pdf2 ? "border-[#3E6BE4]" : "border-[#A6A6A6]"} rounded-[8px]`} onClick={() => setPdf2Selected(!pdf2)}>
                  <div className="flex w-full items-start justify-between gap-[16px] ">
                    <FileDown color={pdf2 ? "#3E6BE4" : "#A6A6A6"} />
                    {pdf2 && <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.75" y="0.75" width="18.5" height="18.5" rx="9.25" stroke="#3E6BE4" stroke-width="1.5" />
                      <path d="M5 10.2368L9 14.5L15 5.5" stroke="#3E6BE4" stroke-width="1.5" stroke-linecap="round" />
                    </svg>}
                  </div>
                  <p className={`leading-[14px] text-[14px] font-semibold ${pdf2 ? "text-[#3E6BE4]" : "text-[#000000]"}`}>10 ETF-ova koje svaki investitor treba da zna</p>
                </div>
              </div>
            </div>
            <div className="lg:mr-auto w-full">
              <button
                disabled={status === "pending"}
                type="submit"
                className={` text-[white] lg:w-[150px] h-[40px] text-[14px] lg:max-w-[150px] lg:mr-auto bg-[#3E6BE4] hover:bg-[#335dcc] !rounded-[50px] !w-full`}
              >
                {status === "pending" ? (
                  <div className="border-t-transparent mx-auto border-solid animate-spin rounded-full border-blue-500 border-2 h-3 w-3"></div>
                ) : "Preuzmi"}
              </button>
              {pdfError &&
                <div className="flex pt-[14px] items-start justify-start gap-[9.33px] mr-auto">
                  <Info size={18} color="#FF2828" className="pt-[3px]" />
                  <p className="text-center text-[12px] lg:text-[14px] text-[#FF2828]">Došlo je do greške, nijedan dokument nije izabran.<br />
                    Odaberite jedan ili oba dokumenta za preuzimanje</p>
                </div>
              }
            </div>

            {status === "success" && seconds > 0 && (
              <div className="fixed z-[999999999999] top-0 left-0 bg-[#FFFFFFD9] lg:bg-transparent w-full h-screen">
                <div className="bg-[#FFFFFF] lg:bg-[#FFFFFF80] lg:mt-[60px] lg:backdrop-blur-[15px] shadow-[2px_4px_8px_0px_#00000029] flex flex-col items-center justify-center w-[320px] rounded-[14px] absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 px-[16px] py-[36px]">
                  <Check color="#C8E64C" size={28} strokeWidth={6} className='flex-shrink-0 lg:mt-0' />
                  <p className="font-semibold text-[18px] leading-[22px] pt-[25.33px] pb-[4px]">Preuzimanje uspješno!</p>
                  <p className="text-[12px] text-center leading-[16px]">
                    Hvala ti što vjeruješ našem timu - zajedno <br /> gradimo tvoju sigurno finansijsku budućnost!</p>
                  <button onClick={() => setSeconds(0)} className="bg-[#C8E64C] rounded-full w-[150px] h-[40px] mt-[24px]">U redu</button>
                  <p className="text-[10px] pt-[12px] text-[#A6A6A6]">Ovaj prozor će se zatvoriti za {seconds}</p>
                </div>
              </div>
            )}

          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ContactSectionNew;
