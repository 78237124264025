import React from 'react';
import iphone from '../assets/iphone16.svg';
import iphoneMock from '../assets/iphone23.svg';
import iphone4 from '../assets/iPhone-16-Pro.png';
import iphoneTFMobile from '../assets/iphone16TF.png';
import iphoneTF from '../assets/iphone16TFs.png';
import tfAppLogo from '../assets/tfAppLogo.png';
import tfStednja from '../assets/TF-Stednja-Banner1.png';
import tfStednjaMobile from '../assets/TF-Stednja-Banner.png';
import dollarSign from '../assets/dollarSign.png';
import logos from '../assets/logoImgs.png';
import videoMockup from '../assets/TF-Štednja-screen-rec-v2.mp4';
import iphoneVideoMockup from '../assets/iPhone-16-Pro-Max-Mockup-no-glow-2025.png';
import { useEffect, useState, useRef } from 'react';
import { Check, X } from 'lucide-react';
import ContactSectionNew from "../components/ContactSectionNew/ContactSectionNew";
import { motion, AnimatePresence } from "framer-motion";

const PlusIcon = (props) => {
  return (
    <svg className={props.className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0C7.16288 0 0 7.16288 0 16C0 24.8371 7.16288 32 16 32C24.8371 32 32 24.8371 32 16C32 7.16288 24.8371 0 16 0ZM24.32 17.28H17.28V24.32C17.28 24.6595 17.1451 24.9851 16.9051 25.2251C16.665 25.4651 16.3395 25.6 16 25.6C15.6605 25.6 15.3349 25.4651 15.0949 25.2251C14.8549 24.9851 14.72 24.6595 14.72 24.32V17.28H7.68C7.34052 17.28 7.01495 17.1451 6.7749 16.9051C6.53486 16.665 6.4 16.3395 6.4 16C6.4 15.6605 6.53486 15.3349 6.7749 15.0949C7.01495 14.8549 7.34052 14.72 7.68 14.72H14.72V7.68C14.72 7.34052 14.8549 7.01495 15.0949 6.7749C15.3349 6.53486 15.6605 6.4 16 6.4C16.3395 6.4 16.665 6.53486 16.9051 6.7749C17.1451 7.01495 17.28 7.34052 17.28 7.68V14.72H24.32C24.6595 14.72 24.9851 14.8549 25.2251 15.0949C25.4651 15.3349 25.6 15.6605 25.6 16C25.6 16.3395 25.4651 16.665 25.2251 16.9051C24.9851 17.1451 24.6595 17.28 24.32 17.28Z" fill={props.color} />
    </svg>
  );
};
const XIcon = (props) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9" cy="9" r="9" fill="#293B84" />
      <path d="M5.80162 11.5208L11.5238 5.79866L12.2967 6.57156L6.57451 12.2937L5.80162 11.5208ZM5.80162 6.56704L6.56999 5.79866L12.2967 11.5253L11.5283 12.2937L5.80162 6.56704Z" fill="white" />
    </svg>
  );
};

const TableMobile = () => {
  return (
    <div className='block lg:hidden px-[16px] bg-[#12172C]'>
      <div className='flex items-center justify-center gap-[12px]'>
        <button className='text-[#00D3FF] border-[1px] border-[#00D3FF] rounded-[50px] w-[156px] h-[42px] mt-[8px]' style={{ boxShadow: '0px 0px 10px 0px #00D3FF33' }}>TF Štednja</button>
        <button className='text-[#E8EAEF] border-[1px] border-[#E8EAEF] rounded-[50px] w-[176px] h-[42px] mt-[8px]'>Oročena štednja</button>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Dnevni obračun - mjesečni pripis kamate</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Efekat složene kamate</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Sredstva uvijek dostupna za nova ulaganja ili povlačenje na račun</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Bez skrivenih troškova i naknada</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Jednostavna prijava i odjava iz programa štednje</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Jednostavna prijava i odjava iz programa štednje</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Osiguranje do 1 milion USD po korisniku</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Uvid u dnevni obračun</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Bez roka oročenja uloženog novca</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
      <div className='pt-[32px]'>
        <p className='text-[14px] leading-[21px] pb-[16px] text-center'>Bez obaveznih periodičnih uplata</p>
        <div className='flex items-center justify-center gap-[150px] pb-[24px] border-b-[1px] border-[#D9D9D933]'>
          <Check color="#00D3FF" size={24} className='flex-shrink-0  lg:mt-0' />
          <X color="#FFFFFF80" size={24} className='flex-shrink-0  lg:mt-0' />
        </div>
      </div>
    </div>
  );
};

const Saving = () => {
  const [visible, setVisible] = useState(false);
  const [usingTf, setUsingTf] = useState(true);
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);
  const [isFlipped3, setIsFlipped3] = useState(false);
  const [isFlipped4, setIsFlipped4] = useState(false);
  const [isFlipped5, setIsFlipped5] = useState(false);
  const [isFlipped6, setIsFlipped6] = useState(false);
  const [isFlipped7, setIsFlipped7] = useState(false);

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.play();

      // Prevent user from pausing the video
      const preventPause = () => {
        video.play();
      };

      video.addEventListener("pause", preventPause);
      return () => {
        video.removeEventListener("pause", preventPause);
      };
    }
  }, []);

  const contentVariants = {
    hiddenLeft: { opacity: 0, x: "-100%" },
    hiddenRight: { opacity: 0, x: "100%" },
    visible: { opacity: 1, x: 0, transition: { duration: 0.6 } },
    exitLeft: { opacity: 0, x: "-100%", transition: { duration: 0.6 } },
    exitRight: { opacity: 0, x: "100%", transition: { duration: 0.6 } },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 4000);

    return () => clearTimeout(timer); // Cleanup in case component unmounts
  }, []);
  return (
    <div className='bg-[#12172C] w-full h-auto mt-[-95px] pt-[154px] text-white'>
      <div className='relative h-auto max-w-[1200px] mx-auto'>
        {/* lights */}
        <div className='h-[800px] absolute top-0 left-0 w-full'>
          <div className='absolute bottom-[100px] right-1/2  w-[484.22px] blur-[200px] h-[484.22px] bg-[#1200D433] rounded-full'></div>
          <div className='absolute bottom-0 left-[60%] transform -translate-x-1/2  w-[305.22px] blur-[400px] h-[305.22px] bg-[#00D3FF33] rounded-full'></div>
        </div>

        <div className='text-center block lg:hidden'>
          <h1 className='text-[52px] font-bold leading-[52.8px] text-white pb-[32px]'>Tvoja štednja <br /> zaslužuje <br /> <span className='text-[#C8E64C] font-bold'>3,75%</span> više! </h1>
          <p className='text-[18px] leading-[21.6px]'>Pasivna zarada od štednje? <br />Sada je moguća uz garantovani godišnji<br /> prinos od <span className='text-[#C8E64C] font-bold'>3,75%.</span></p>
        </div>
        <div className='text-center lg:block hidden'>
          <h1 className='text-[54px] font-bold leading-[59.4px] text-white pb-[32px]'>Tvoja štednja <br /> zaslužuje <span className='text-[#C8E64C] font-bold'>3,75%</span> više! </h1>
          <p className='text-[18px] leading-[21.6px]'><b>Pasivna zarada od štednje?</b> Sada je moguća uz<br /> garantovani godišnji prinos od <span className='text-[#C8E64C] font-bold'>3,75%.</span></p>
        </div>
        <div className='mt-[50px] flex items-center h-full overflow-hidden justify-center'>
          <div className='relative'>
            <img src={iphone} className={`w-[263.3px] relative lg:w-[456px] h-[557.77px] lg:h-[951px] ${visible ? "z-[2]" : "z-[2]"}`} alt="Iphone image" />
            <img
              src={tfStednja}
              alt="stednja"
              className={`hidden lg:block z-[9] w-[253.3px] lg:w-[426px] h-[547.77px] lg:h-[931px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-opacity duration-1000 ${visible ? "opacity-100" : "opacity-0"}`}
            />
            <img
              src={tfStednjaMobile}
              alt="stednja"
              className={`lg:hidden block z-[9] w-[253.3px] lg:w-[426px] h-[547.77px] rounded-[15px] lg:h-[931px] absolute top-[51%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[5] transition-all duration-1000 ${visible ? "opacity-100" : "opacity-0"}`}
            />
            <div className=''>
              <div className='absolute top-[79px] lg:top-[130px] left-[10px] lg:left-[40px] rounded-[10px] z-[9] w-[230px] lg:w-[365px] h-[40px] lg:h-[60px] bg-[#12162D] flex items-center justify-between'>
                <img src={tfAppLogo} alt="" className='w-[105px] lg:w-[140px] h-[38px] lg:h-[48px] ml-[5px] mt-[-3px]' />
                <div className='border-[#1E3671] border-[1px] rounded-full p-[2px] w-[30.26px] lg:w-[51px] lg:h-[32px] h-[20.99px] mr-[5] relative'>
                  <div className={`rounded-full w-[15.43px] lg:w-[26px] lg:h-[26px] h-[15.43px] transition-all absolute ${visible ? "left-[12px] lg:left-[20px] duration-1000 bg-[#3E6BE4]" : "left-[2px] duration-300 bg-[#1E3671]"
                    }`}

                  ></div>
                </div>
              </div>
              <img className='hidden lg:block z-[5] rounded-[30px] bg-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[254px] h-[551px] lg:rounded-[60px] lg:w-[426px] lg:h-[931px]' src={iphoneTF} />
              <img className='block lg:hidden z-[5] rounded-[30px] bg-black absolute top-[51%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[254px] h-[551px] lg:rounded-[60px] rounded-[40px] lg:w-[426px] lg:h-[931px]' src={iphoneTFMobile} />
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#070D19] mt-[-150px] lg:h-[800px] lg:mt-[-530px] pb-[64px] z-[999] relative overflow-hidden'>
        {/* lights */}
        <div className='absolute bottom-[-120px] hidden lg:block left-1/2 transform -translate-x-1/2 w-[428px] blur-[300px] h-[458px] bg-[#1200D480] rounded-full'></div>
        <div className='absolute bottom-0 hidden lg:block right-0 w-[366px] blur-[400px] h-[352px] bg-[#00D3FF80] rounded-full'></div>
        <div className='max-w-[1200px] mx-auto lg:flex block items-start'>
          <div style={{ boxShadow: "0px 0px 200px 16px #00D3FF" }} className="w-full lg:hidden h-[0.5px] absolute top-0 transform -translate-x-1/2 left-1/2 bg-[#00D3FF]">
          </div>
          <div className='pl-[16px] lg:pt-[140px]'>
            <h2 className='pt-[63.5px] lg:pt-0 font-bold text-[32px] lg:text-[42px] lg:leading-[54.6px] leading-[41.6px] pb-[20px]'>Osmišljeno za <br /> <span className='underline' style={{ background: 'linear-gradient(90.33deg, #FFFFFF 0.17%, #BCC9D6 100.25%)', backgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>štednju.</span><br />
              Optimizovano za <br /> <span className='underline' style={{ background: 'linear-gradient(90.33deg, #FFFFFF 0.17%, #BCC9D6 100.25%)', backgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>investitore.</span></h2>
            <p className='max-w-[445px] text-[18px] leading-[25.2px] text-[#D9D9D9] mb-[64px]'>Tvoj prinos se pripisuje svakog dana, isplaćuje svakog mjeseca i raste svakom uplatom.</p>
          </div>

          <div className="grid grid-cols-2 lg:pt-[140px] mx-auto w-full relative lg:h-[800px] overflow-y-auto overflow-x-hidden lg:w-[620px] md:grid-cols- gap-[15px] p-[16px] lg:pb-[150px] lg:pt-0 text-white">
            {/* First Column */}
            <div className='flex flex-col gap-[10.33px]'>
              {/* Original Card 1 - Already has flip */}
              <div className="w-full h-[169px] lg:w-[280px] lg:h-[280px] perspective-1000">

                <div
                  className="relative w-full h-full transition-transform duration-500 ease-in-out"
                  style={{
                    transform: isFlipped1 ? 'rotateY(180deg)' : 'rotateY(0deg)',
                    transformStyle: 'preserve-3d',
                  }}
                >
                  {/* Front Side */}
                  <div
                    style={{
                      background: 'linear-gradient(135deg, #1200D4 0%, #3E6BE4 49%, #00D3FF 100%)',
                      clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)',
                    }}
                    className="absolute w-full h-full overflow-hidden bg-blue-500 rounded-[10px] backface-hidden"
                  >
                    <div className="pl-[16px] pt-[24px] lg:pl-[30px] lg:pt-[48px]">
                      <p className='text-[20px] lg:text-[34px] lg:leading-[37.4px] leading-[22px] font-[300]'>
                        Bez ikakvih naknada.
                      </p>
                      <p className='text-[20px] lg:text-[34px] lg:leading-[37.4px] leading-[22px] font-bold'>
                        Čak ni onih skrivenih.
                      </p>
                    </div>
                    <div
                      className='absolute bottom-[16px] right-[16px] cursor-pointer'
                      onClick={() => setIsFlipped1(!isFlipped1)}
                    >
                      <PlusIcon color='white' className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                    </div>
                  </div>

                  {/* Back Side */}
                  <div
                    className="absolute w-full h-full bg-white rounded-[10px] px-[10px] lg:px-[15px] pt-[15px] backface-hidden"
                    style={{
                      transform: 'rotateY(180deg)',
                      clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)',
                    }}
                  >
                    <div className="text-black">
                      <h3 className="font-bold lg:text-[20px] text-[12px] text-[#293B84]">Bez skrivenih troškova</h3>
                      <p className="mt-[12px] lg:text-[16px] text-[9px]">
                        Želimo da ti olakšamo da uštediš novac, a ne da ti otežamo. TF Štednja nema nikakvih naknada.
                        Ni za uplatu depozita, niti za isplatu na svoj bankovni račun — <b>zato što TF Štednja radi za tebe, a ne protiv.</b>
                      </p>
                    </div>
                    <div
                      className='absolute bottom-[16px] right-[16px] cursor-pointer'
                      onClick={() => setIsFlipped1(!isFlipped1)}
                    >
                      <XIcon />
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2 - Added flip */}
              <div className="w-full h-[258.75px] lg:w-[280px] lg:h-[430px] perspective-1000">
                <div className={`relative w-full h-full transition-transform duration-500 ease-in-out`}
                  style={{ transform: isFlipped2 ? 'rotateY(180deg)' : 'rotateY(0deg)', transformStyle: 'preserve-3d' }}>
                  {/* Front Side */}
                  <div style={{
                    background: '#3E6BE4', clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)',
                  }} className="absolute overflow-hidden w-full h-full rounded-[10px] flex flex-col items-start justify-start pt-[10px] backface-hidden">

                    <img src={dollarSign} alt="Dollar" className='w-[44.5px] h-[87.25px] lg:w-[73.94px] lg:h-[145px] text-center mx-auto' />
                    <p className='pl-[16px] pt-[16px] text-[20px] leading-[24px] lg:text-[34px] lg:leading-[40.8px]'>
                      Osigurana<br /> sredstva <br /> štednje do <br /> <b> 1.000.000 <br /> USD.</b>
                    </p>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped2(!isFlipped2)}>
                      <PlusIcon color='white' className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                    </div>
                  </div>
                  {/* Back Side */}
                  <div className="absolute w-full overflow-hidden h-full bg-white rounded-[10px] lg:px-[15px] px-[10px] pt-[15px] backface-hidden" style={{ transform: 'rotateY(180deg)', clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }}>

                    <div className="text-black">
                      <h3 className="font-bold text-[12px] lg:text-[20px] text-[#293B84]">Vaša sigurnost.<br />
                        Naš prioritet.</h3>
                      <p className="mt-[12px] lg:text-[16px] text-[9px]">Sredstva pod TF Štednjom su osigurana do <b>1 milion dolara po korisniku.</b> Ovu zaštitu pruža Savezna korporacija za osiguranje depozita <b>(FDIC)</b> – jedna od najpouzdanijih finansijskih institucija na svijetu.
                        Uz TF Štednju, vaš novac ne samo da raste, već je zaštićen na najvišem nivou. Mirna finansijska budućnost? Sada je to jednostavno.</p>
                    </div>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped2(!isFlipped2)}>
                      <XIcon />
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 3 - Added flip */}
              <div className="w-full h-[182px] lg:w-[280px] lg:h-[304px] perspective-1000">
                <div className={`relative w-full h-full transition-transform duration-500 ease-in-out`}
                  style={{ transform: isFlipped3 ? 'rotateY(180deg)' : 'rotateY(0deg)', transformStyle: 'preserve-3d' }}>
                  {/* Front Side */}
                  <div style={{ clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }} className='absolute w-full overflow-hidden h-full bg-white rounded-[10px] flex flex-col justify-center pl-[16px] lg:pl-[30px] backface-hidden'>

                    <div className='w-[42.47px] h-[42.47px] absolute top-[19px] right-[17px] bg-[#C8E64C1A] rounded-full flex items-center justify-center'>
                      <div className='w-[35.93px] h-[35.93px] bg-[#C8E64C4D] rounded-full flex items-center justify-center'>
                        <div className='bg-[#C8E64C] w-[21.23px] h-[21.23px] rounded-full'></div>
                      </div>
                    </div>
                    <p className='text-black text-[18px] leading-[19.8px] lg:text-[34px] lg:leading-[37px]'>
                      Uključi.<br /> Isključi.<br />
                      <span className='text-[#293B84] font-bold'>Jedan klik.</span>
                    </p>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped3(!isFlipped3)}>
                      <PlusIcon color="#293B84" className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                    </div>
                  </div>
                  {/* Back Side */}
                  <div className="absolute w-full overflow-hidden h-full bg-white rounded-[10px] px-[10px] lg:px-[15px] pt-[15px] backface-hidden" style={{ transform: 'rotateY(180deg)', clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }}>

                    <div className="text-black">
                      <h3 className="font-bold lg:text-[20px] text-[12px] text-[#293B84]">Na dodir do štednje</h3>
                      <p className="mt-[12px] lg:text-[16px] text-[9px]">Aktivacija je prejednostavna. Dodirni “USD interest” polje i prihvati najnovije uslove u TF Trader aplikaciji i tvoj novac odmah počinje da zarađuje.</p>
                    </div>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped3(!isFlipped3)}>
                      <XIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Second Column */}
            <div className='flex flex-col gap-[10.33px]'>
              {/* Card 4 - Added flip */}
              <div className="w-full h-[258.75px] lg:w-[280px] lg:h-[430px] perspective-1000">
                <div className={`relative w-full h-full transition-transform duration-500 ease-in-out`}
                  style={{ transform: isFlipped4 ? 'rotateY(180deg)' : 'rotateY(0deg)', transformStyle: 'preserve-3d' }}>
                  {/* Front Side */}
                  <div style={{ clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }} className='absolute overflow-hidden w-full h-full bg-white rounded-[10px] pt-[60px] pl-[16px] lg:pt-[100px] lg:pl-[24px] text-[#293B84] backface-hidden'>

                    <p className='text-[20px] leading-[22px] lg:text-[34px] lg:leading-[40.8px]'>
                      Prva štednja u Crnoj Gori koja ne blokira tvoj novac na godišnjem nivou.
                    </p>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped4(!isFlipped4)}>
                      <PlusIcon color="#293B84" className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                    </div>
                  </div>
                  {/* Back Side */}
                  <div className="absolute w-full overflow-hidden h-full bg-white rounded-[10px] px-[10px] lg:px-[15px] pt-[15px] backface-hidden" style={{ transform: 'rotateY(180deg)', clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }}>
                    <div className='absolute top-[-50px] right-[-10px] bg-black rotate-[130deg] w-[40px] h-[90px] z-[5]'>

                    </div>
                    <div className="text-black">
                      <h3 className="font-bold lg:text-[20px] text-[12px] text-[#293B84]">Bez oročenja</h3>
                      <p className="mt-[12px] lg:text-[16px] text-[9px]">Naša vizija je da u Crnu Goru uvedemo proizvode za investiranje kao dio osnovnog finansijskog obrazovanja. Stoga smo, umjesto oročene štednje, kreirali kontinuiranu štednju koja ti omogućava da u svakom trenutku imaš svoj novac na raspolaganju. Novac se dnevno uvećava i dostupan je za isplatu na tvoj bankovni račun ili za investiranje na USA berzama <b>— u svakom momentu.</b></p>
                    </div>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped4(!isFlipped4)}>
                      <XIcon />
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 5 - Added flip */}
              <div className="w-full h-[118.5px] lg:w-[280px] lg:h-[197px] perspective-1000">
                <div className={`relative w-full h-full transition-transform duration-500 ease-in-out`}
                  style={{ transform: isFlipped5 ? 'rotateY(180deg)' : 'rotateY(0deg)', transformStyle: 'preserve-3d' }}>
                  {/* Front Side */}
                  <div style={{ background: 'linear-gradient(180deg, #293B84 0%, #1101D4 100%)', clipPath: 'polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)' }}
                    className='absolute w-full overflow-hidden h-full rounded-[10px] pt-[24px] pl-[16px] lg:pt-[49px] lg:pl-[24px] backface-hidden'>
                    <div className={`absolute top-[-50px] right-[-10px] bg-black rotate-[130deg] w-[40px] h-[90px] z-[5] ${isFlipped5 ? "hidden" : 'block'}`}>

                    </div>
                    <p className='text-[#C8E64C] text-[32px] leading-[35px] lg:text-[56px] lg:leading-[61px] font-bold pb-[12px]'>+3,75%</p>
                    <p className='text-[14px] leading-[15px] font-bold text-white lg:text-[20px] lg:leading-[22px]'>
                      Godišnji<br /> prinos.
                    </p>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped5(!isFlipped5)}>
                      <PlusIcon color="#C8E64C" className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                    </div>
                  </div>
                  {/* Back Side */}
                  <div className="absolute w-full overflow-hidden h-full bg-white rounded-[10px] px-[10px] lg:px-[15px] pt-[15px] backface-hidden" style={{ transform: 'rotateY(180deg)', clipPath: 'polygon(0% 0%, 85% 0%, 100% 20%, 100% 100%, 0% 100%)' }}>
                    <div className='absolute top-[-50px] right-[-10px] bg-black rotate-[130deg] w-[40px] h-[90px] z-[5]'>

                    </div>
                    <div className="text-black">
                      <h3 className="font-bold text-[12px] lg:text-[20px] text-[#293B84]">Očekivani prinos</h3>
                      <p className="mt-[12px] lg:text-[16px] text-[9px]">Očekivani godišnji prinos (APR) iznosi 3,75%, uz prilagođavanje prema referentnoj stopi FED-a.</p>
                    </div>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped5(!isFlipped5)}>
                      <XIcon />
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 6 - Added flip */}
              <div className="w-full h-[237.5px] lg:w-[280px] lg:h-[387px] perspective-1000">
                <div className={`relative w-full h-full transition-transform duration-500 ease-in-out`}
                  style={{ transform: isFlipped6 ? 'rotateY(180deg)' : 'rotateY(0deg)', transformStyle: 'preserve-3d' }}>
                  {/* Front Side */}
                  <div style={{ clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }} className='absolute overflow-hidden w-full h-full bg-[#1101D4] rounded-[10px] pt-[24px] pl-[16px] lg:pt-[64px] lg:pl-[32px] pr-[20px] lg:pr-[24px] backface-hidden'>

                    <p className='text-[16px] leading-[17.9px] lg:text-[28px] lg:leading-[30.8px]'>
                      Iskoristi novac za investicione prilike na <b>USA berzama.</b>
                    </p>
                    <div>
                      <img className='absolute right-0 bottom-[55px] lg:bottom-[75px] w-[143.7px] h-[63.7px] lg:w-[241px] lg:h-[95px]'
                        src={logos} alt="META, TESLA, GOOGLE, AMAZON" />
                    </div>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped6(!isFlipped6)}>
                      <PlusIcon color="white" className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                    </div>
                  </div>
                  {/* Back Side */}
                  <div className="absolute overflow-hidden w-full h-full bg-white rounded-[10px] px-[10px] lg:px-[15px] pt-[15px] backface-hidden" style={{ transform: 'rotateY(180deg)', clipPath: 'polygon(0% 0%, 85% 0%, 100% 12%, 100% 100%, 0% 100%)' }}>
                    <div className='absolute top-[-50px] right-[-10px] bg-black rotate-[130deg] w-[40px] h-[90px] z-[5]'>

                    </div>
                    <div className="text-black">
                      <h3 className="font-bold text-[12px] lg:text-[20px] text-[#293B84]">Štedi. Uštedi. Investiraj.</h3>
                      <p className="mt-[12px] lg:text-[16px] text-[9px]">Tvoj novac na TF Štednji nije zaključan. Uvijek ga možeš iskoristiti za nove investicione prilike na USA berzi—direktno kroz TF Trader aplikaciju. Štedi danas, investiraj kad odlučiš da je pravi trenutak.</p>
                    </div>
                    <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped6(!isFlipped6)}>
                      <XIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Full-width Card 7 - Added flip */}
            <div className="col-span-2 w-full lg:w-[570px] h-[247px] lg:h-[388px] perspective-1000">
              <div className={`relative w-full h-full transition-transform duration-500 ease-in-out`}
                style={{ transform: isFlipped7 ? 'rotateY(180deg)' : 'rotateY(0deg)', transformStyle: 'preserve-3d' }}>
                {/* Front Side */}
                <div style={{ clipPath: 'polygon(0% 0%, 90% 0%, 100% 15%, 100% 100%, 0% 100%)', background: 'radial-gradient(93.89% 93.89% at 35.49% 12.41%, #1101D4 0%, #3E6BE4 65.64%, #05CCFD 100%)' }}
                  className="absolute w-full overflow-hidden h-full rounded-[10px] overflow-hidden p-6 backface-hidden">
                  <div className={`absolute top-[-50px] right-[-10px] bg-black rotate-[130deg] w-[40px] h-[90px] z-[5] ${isFlipped7 ? "hidden" : 'block'}`}>

                  </div>
                  <p className="text-[24px] leading-[26.4px] lg:text-[44.65px] lg:leading-[53.58px]">
                    Tvoj novac raste<br /> <span className="text-white font-bold">iz dana u dan</span> <br />čitave godine.
                  </p>
                  <p className='text-[12px] leading-[13.2px] lg:text-[16px] lg:leading-[17.6px] font-bold pt-[15.5px]'>
                    Automatski štediš i zarađuješ pripisanu kamatu.
                  </p>
                  <svg className='absolute left-0 lg:block hidden' width="569" height="111" viewBox="0 0 569 111" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 106.066L124.874 102.555L244.864 93.4239L377.412 70.9482L498.1 40.0442L586 2.81885" stroke="url(#paint0_linear_290_919)" stroke-width="4.18571" stroke-linecap="round" />
                    <circle cx="118.596" cy="102.486" r="6.27857" fill="#2A8BEC" stroke="#F3F9D6" stroke-width="4.18571" />
                    <path d="M253.236 92.1141C253.236 95.5817 250.425 98.3927 246.957 98.3927C243.49 98.3927 240.679 95.5817 240.679 92.1141C240.679 88.6465 243.49 85.8355 246.957 85.8355C250.425 85.8355 253.236 88.6465 253.236 92.1141Z" fill="#2891EE" stroke="#E4F3A8" stroke-width="4.18571" />
                    <path d="M377.412 70.4881C377.412 73.9557 374.601 76.7667 371.133 76.7667C367.666 76.7667 364.855 73.9557 364.855 70.4881C364.855 67.0206 367.666 64.2096 371.133 64.2096C374.601 64.2096 377.412 67.0206 377.412 70.4881Z" fill="#1DA3F3" stroke="#D8ED80" stroke-width="4.18571" />
                    <path d="M499.496 41.3976C499.496 44.8651 496.685 47.6761 493.217 47.6761C489.75 47.6761 486.939 44.8651 486.939 41.3976C486.939 37.93 489.75 35.119 493.217 35.119C496.685 35.119 499.496 37.93 499.496 41.3976Z" fill="#0FBBF8" stroke="#CEE95E" stroke-width="4.18571" />
                    <defs>
                      <linearGradient id="paint0_linear_290_919" x1="0" y1="60.0616" x2="582.512" y2="60.0616" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#C8E64C" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <svg className='absolute left-0 block lg:hidden' width="343" height="67" viewBox="0 0 343 67" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 64.109L75.1434 61.9957L147.348 56.5012L227.109 42.9764L299.734 24.3798L352.628 1.97925" stroke="url(#paint0_linear_290_794)" stroke-width="2.51877" stroke-linecap="round" />
                    <circle cx="71.3657" cy="61.9543" r="3.77816" fill="#2A8BEC" stroke="#F3F9D6" stroke-width="2.51877" />
                    <path d="M152.386 55.7131C152.386 57.7997 150.694 59.4912 148.608 59.4912C146.521 59.4912 144.83 57.7997 144.83 55.7131C144.83 53.6265 146.521 51.9349 148.608 51.9349C150.694 51.9349 152.386 53.6265 152.386 55.7131Z" fill="#2891EE" stroke="#E4F3A8" stroke-width="2.51877" />
                    <path d="M227.109 43.6997C227.109 45.7863 225.417 47.4778 223.331 47.4778C221.244 47.4778 219.552 45.7863 219.552 43.6997C219.552 41.613 221.244 39.9215 223.331 39.9215C225.417 39.9215 227.109 41.613 227.109 43.6997Z" fill="#1DA3F3" stroke="#D8ED80" stroke-width="2.51877" />
                    <path d="M300.574 25.1943C300.574 27.2809 298.882 28.9724 296.795 28.9724C294.709 28.9724 293.017 27.2809 293.017 25.1943C293.017 23.1077 294.709 21.4161 296.795 21.4161C298.882 21.4161 300.574 23.1077 300.574 25.1943Z" fill="#0FBBF8" stroke="#CEE95E" stroke-width="2.51877" />
                    <defs>
                      <linearGradient id="paint0_linear_290_794" x1="0" y1="36.4253" x2="350.529" y2="36.4253" gradientUnits="userSpaceOnUse">
                        <stop stop-color="white" />
                        <stop offset="1" stop-color="#C8E64C" />
                      </linearGradient>
                    </defs>
                  </svg>

                  <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped7(!isFlipped7)}>
                    <PlusIcon color="white" className="w-[20px] h-[20px] lg:w-[32px] lg:h-[32px]" />
                  </div>
                </div>
                {/* Back Side */}
                <div className="absolute overflow-hidden w-full h-full bg-white rounded-[10px] px-[10px] lg:px-[15px] pt-[15px] backface-hidden" style={{ transform: 'rotateY(180deg)', clipPath: 'polygon(0% 0%, 90% 0%, 100% 15%, 100% 100%, 0% 100%)' }}>
                  <div className='absolute top-[-50px] right-[-10px] bg-black rotate-[130deg] w-[40px] h-[90px] z-[5]'>

                  </div>
                  <div className="text-black max-w-[130px] lg:max-w-[240px]">
                    <h3 className="font-bold lg:text-[20px] text-[12px] text-[#293B84]">Štednja drži tempo</h3>
                    <p className="mt-[12px] lg:text-[16px] text-[9px]">Prinosi se redovno obračunavaju i jasno prikazuju na tvom računu. Kamata se pripisuje dnevno, a obračunava na mjesečnom nivou. Bilo da štediš za buduće planove ili čekaš pravi trenutak za investiranje, preuzmi TF Trader, deponuj sredstva <b> — i neka novac radi za tebe.</b></p>
                  </div>
                  <div className='absolute bottom-[16px] right-[16px] cursor-pointer' onClick={() => setIsFlipped7(!isFlipped7)}>
                    <XIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full lg:bg-[#070D19] bg-[#12172C]'>
        <div className='block lg:hidden px-[16px]'>
          <h2 className='pt-[63.5px] font-bold text-[32px] leading-[41.6px] pb-[20px]'>Doviđenja oročenoj.<br />
            Dobro nam došla, <br />
            <span className='text-[#C8E64C]'>TF štednja.</span></h2>
          <p className='text-[16px] leading-[19.2px] text-[#D9D9D9] mb-[64px]'>Klasična štednja zaključava. TF Štednja oslobađa. Procijeni svoj rizik.</p>
        </div>
        <div className='lg:block hidden max-w-[706px] text-center mx-auto'>
          <h2 className=' pt-[140px] font-bold text-[32px] leading-[41.6px] pb-[20px] lg:text-[42px] lg:leading-[50.4px]'>Doviđenja oročenoj.<br />
            Dobro nam došla,
            <span className='text-[#C8E64C]'>TF štednja.</span></h2>
          <p className='text-[16px] leading-[19.2px] text-[#D9D9D9] mb-[64px] lg:text-[18px] lg:leading-[25.2px]'>Klasična štednja zaključava. TF Štednja oslobađa.<br /> Procijeni svoj rizik.</p>
        </div>
        <TableMobile />
        <div className='hidden lg:block bg-[#12172C] max-w-[1200px] mx-auto px-[48px] rounded-[16px] py-[32px]'>
          <div className='overflow-x-auto mx-auto lg:justify-between lg:max-w-[1174px] lg:px-[40px] xl:px-0  relative flex items-start justify-start'>
            <div className='flex flex-col items-start justify-start pb-[38px] w-fit'>
              <p className='text-[24px] leading-[42px]'></p>
              <div className='flex flex-col items-center justify-center gap-[41px] mt-[80px]'>
                <div className=' flex items-center justify-between w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Dnevni obračun - mjesečni pripis kamate</p>
                </div>
                <div className=' flex items-center justify-between w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Efekat složene kamate</p>
                </div>
                <div className=' flex items-center justify-between w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Sredstva uvijek dostupna za nova ulaganja ili povlačenje na račun</p>
                </div>
                <div className=' flex items-center justify-between w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Bez skrivenih troškova i naknada</p>
                </div>
                <div className=' flex items-center justify-between  w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Jednostavna prijava i odjava iz programa štednje</p>
                </div>
                <div className=' flex items-center justify-between  w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Osiguranje do 1 milion USD po korisniku</p>
                </div>
                <div className=' flex items-center justify-between  w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Uvid u dnevni obračun</p>
                </div>
                <div className=' flex items-center justify-between  w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Bez roka oročenja uloženog novca</p>
                </div>
                <div className=' flex items-center justify-between  w-full'>
                  <p className='w-[200px] lg:w-fit flex-shrink-0 text-[14px] leading-[21px]'>Bez obaveznih periodičnih uplata</p>
                </div>
              </div>
            </div>
            <div className='flex items-start gap-[40px]'>
              <div className='flex flex-col justify-center lg:gap-[38px] items-center'>
                <button className='text-[#00D3FF] cursor-default border-[1px] border-[#00D3FF] rounded-[50px] w-[136px] h-[42px] mt-[8px]' style={{ boxShadow: '0px 0px 10px 0px #00D3FF33' }}>TF Štednja</button>
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[38px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[48px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[66px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[36px] lg:mt-0' />
                <Check color="#00D3FF" size={24} className='flex-shrink-0 mt-[36px] lg:mt-0' />

              </div>
              <div className='flex flex-col justify-center lg:gap-[38px] items-center'>
                <button className='text-[#E8EAEF] cursor-default border-[1px] border-[#E8EAEF] rounded-[50px] w-[176px] h-[42px] mt-[8px]'>Oročena štednja</button>
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[38px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[48px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[66px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[56px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[36px] lg:mt-0' />
                <X color="#FFFFFF80" size={24} className='flex-shrink-0 mt-[36px] lg:mt-0' />

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='bg-[#070D19] px-[16px] lg:pt-[120px] pb-[64px]'>
        <div className='max-w-[1200px] mx-auto'>
          <div className=' max-w-[590px] lg:pb-[80px] pb-[64px] text-center mx-auto'>
            <h2 className='pt-[63.5px] font-bold text-[32px] lg:text-[42px] lg:leading-[50.4px] leading-[41.6px] pb-[20px]'>Da li već koristiš TF Trader?</h2>
            <p className='text-[16px] max-w-[544px] lg:text-[18px] lg:leading-[25.2px] mx-auto leading-[19.2px] text-[#D9D9D9] mb-[20px]'>Pogledaj korake za aktivaciju TF Štednje</p>
          </div>
          <div className='border-[1px] mx-auto border-[#1200D4] w-fit rounded-full flex items-center justify-center'>
            <button onClick={() => setUsingTf(true)} className={`${usingTf && "rounded-full bg-[#1200D4] text-white"} px-[14.2px] lg:px-[30.5px] py-[16px] text-white m-[4px] text-[13px] font-medium leading-[17px]`}>Koristim TF Trader</button>
            <button onClick={() => setUsingTf(false)} className={`${!usingTf && "rounded-full bg-[#1200D4] text-white"} px-[14.2px] lg:px-[30.5px] py-[16px] text-white m-[4px] text-[13px] font-medium leading-[17px]`}>Ne koristim TF Trader</button>
          </div>
          <AnimatePresence mode="wait">
            {
              usingTf ? <>
                <div className='lg:flex items-center justify-between'>
                  <div className=''>
                    <motion.div
                      key="usingTf"
                      initial="hiddenLeft"
                      animate="visible"
                      exit="exitLeft"
                      variants={contentVariants}
                      className=""
                    >
                      <div className='max-w-[488px] mx-auto'>
                        <h2 className='font-bold  text-[30px] leading-[41.6px] pb-[16px] pt-[60px]'>Pokreni TF Štednju</h2>
                        <p className='text-[16px] lg:text-[16px] lg:leading-[25.2px] leading-[19.2px] text-[#D9D9D9] mb-[64px]'>Apliciraj kroz TF Trader za samo 3 klika, manje od jednog minuta tvog vremena.</p>
                      </div>

                      <div className='flex items-start justify-start gap-[24px]'>
                        <svg className='flex-shrink-0' width="48" height="325" viewBox="0 0 48 325" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect x="8" width="32" height="32" rx="16" fill="#1200D4" />
                          <path d="M25.9574 9.90909V23H24.3722V11.571H24.2955L21.0994 13.6932V12.0824L24.3722 9.90909H25.9574Z" fill="white" />
                          <line x1="25" y1="40.8335" x2="25" y2="88.8335" stroke="#1200D4" stroke-width="2" />
                          <rect x="8" y="97.6665" width="32" height="32" rx="16" fill="#E4E4E4" fill-opacity="0.5" />
                          <path d="M20.3551 120.667V119.516L24.6761 114.786C25.1832 114.232 25.6009 113.75 25.929 113.341C26.2571 112.928 26.5 112.54 26.6577 112.178C26.8196 111.811 26.9006 111.428 26.9006 111.027C26.9006 110.567 26.7898 110.169 26.5682 109.832C26.3509 109.495 26.0526 109.235 25.6733 109.052C25.294 108.869 24.8679 108.777 24.3949 108.777C23.892 108.777 23.4531 108.882 23.0781 109.091C22.7074 109.295 22.4197 109.583 22.2152 109.953C22.0149 110.324 21.9148 110.759 21.9148 111.257H20.4062C20.4062 110.49 20.5831 109.817 20.9368 109.238C21.2905 108.658 21.772 108.206 22.3814 107.882C22.995 107.559 23.6832 107.397 24.446 107.397C25.2131 107.397 25.8928 107.559 26.4851 107.882C27.0774 108.206 27.5419 108.643 27.8786 109.193C28.2152 109.742 28.3835 110.354 28.3835 111.027C28.3835 111.509 28.2962 111.98 28.1214 112.44C27.951 112.896 27.6527 113.405 27.2266 113.968C26.8047 114.526 26.2188 115.208 25.4688 116.013L22.5284 119.158V119.26H28.6136V120.667H20.3551Z" fill="black" />
                          <line x1="25" y1="138.5" x2="25" y2="186.5" stroke="#E4E4E4" stroke-opacity="0.5" stroke-width="2" />
                          <rect x="8" y="195.333" width="32" height="32" rx="16" fill="#E4E4E4" fill-opacity="0.5" />
                          <path d="M23.7784 218.512C22.9347 218.512 22.1825 218.368 21.522 218.078C20.8658 217.788 20.3438 217.385 19.956 216.87C19.5724 216.35 19.3636 215.747 19.3295 215.061H20.9403C20.9744 215.483 21.1193 215.847 21.375 216.154C21.6307 216.456 21.9652 216.691 22.3786 216.857C22.7919 217.023 23.25 217.106 23.7528 217.106C24.3153 217.106 24.8139 217.008 25.2486 216.812C25.6832 216.616 26.0241 216.343 26.2713 215.994C26.5185 215.645 26.642 215.24 26.642 214.78C26.642 214.298 26.5227 213.874 26.2841 213.508C26.0455 213.137 25.696 212.847 25.2358 212.638C24.7756 212.429 24.2131 212.325 23.5483 212.325H22.5V210.919H23.5483C24.0682 210.919 24.5241 210.825 24.9162 210.637C25.3125 210.45 25.6214 210.186 25.843 209.845C26.0689 209.504 26.1818 209.103 26.1818 208.643C26.1818 208.2 26.0838 207.814 25.8878 207.486C25.6918 207.158 25.4148 206.902 25.0568 206.719C24.7031 206.536 24.2855 206.444 23.804 206.444C23.3523 206.444 22.9261 206.527 22.5256 206.694C22.1293 206.856 21.8054 207.092 21.554 207.403C21.3026 207.71 21.1662 208.081 21.1449 208.515H19.6108C19.6364 207.829 19.843 207.228 20.2308 206.713C20.6186 206.193 21.1257 205.788 21.7521 205.498C22.3828 205.208 23.0753 205.064 23.8295 205.064C24.6392 205.064 25.3338 205.228 25.9134 205.556C26.4929 205.88 26.9382 206.308 27.2493 206.841C27.5604 207.373 27.7159 207.949 27.7159 208.566C27.7159 209.304 27.522 209.932 27.1342 210.452C26.7507 210.972 26.2287 211.332 25.5682 211.532V211.635C26.3949 211.771 27.0405 212.123 27.505 212.689C27.9695 213.252 28.2017 213.949 28.2017 214.78C28.2017 215.491 28.0078 216.13 27.62 216.697C27.2365 217.26 26.7124 217.703 26.0476 218.027C25.3828 218.351 24.6264 218.512 23.7784 218.512Z" fill="black" />
                          <line x1="25" y1="236.167" x2="25" y2="284.167" stroke="#E4E4E4" stroke-opacity="0.5" stroke-width="2" />
                          <rect x="8" y="293" width="32" height="32" rx="16" fill="#E4E4E4" fill-opacity="0.5" />
                          <path d="M19.0483 313.315V312.011L24.8011 302.909H25.7472V304.929H25.108L20.7614 311.807V311.909H28.5085V313.315H19.0483ZM25.2102 316V312.919V312.312V302.909H26.7188V316H25.2102Z" fill="black" />
                        </svg>

                        <div className='flex flex-col items-start justify-start max-w-[287px] lg:max-w-[400px] gap-[27px] lg:gap-[49px]'>
                          <div className='flex flex-col items-start justify-start gap-[4px] '>
                            <p className='text-[16px] leading-[24px]'>Neinvestirani novac na tvom TF Trader nalogu zarađuje kamatu na dnevnom nivou</p>
                          </div>
                          <div className='flex flex-col items-start justify-start gap-[4px]'>
                            <p className='text-[15px] leading-[24px]'>Dnevni obračun zarade koji ne utiče na dostupnost novca sa kojim raspolažeš</p>
                          </div>
                          <div className='flex flex-col items-start justify-start gap-[4px]'>
                            <p className='text-[16px] leading-[24px]'>Zarađena kamata se pripisuje na tvoj novac na kraju mjeseca.</p>
                          </div>
                          <div className='flex flex-col items-start justify-start gap-[4px] pt-[25px] lg:pt-0'>
                            <p className='text-[16px] leading-[24px]'>Sljedećeg mjeseca, kamata se obračunava na uvećani iznos iz prethodnog mjeseca.</p>
                          </div>
                        </div>
                      </div>
                      <a href='https://onelink.to/48kpsn' className='w-full lg:w-[187px]'><button className='w-full text-[14px] hover:bg-[#335dcc] leading-[14px] h-[40px] bg-[#3E6BE4] mt-[48px] lg:w-[187px] rounded-full'>Prijavi se odmah</button></a>
                    </motion.div>
                  </div>
                  <motion.div
                    key="usingTf1"
                    initial="hiddenRight"
                    animate="visible"
                    exit="exitRight"
                    variants={contentVariants}
                    className=""
                  >

                    <div className=' relative flex items-center justify-center mt-[64px] mx-auto h-[315.25px] h-[652.75px]'>
                      <img src={iphoneVideoMockup} className='h-[315.25px] h-[652.75px] z-[99]' alt="Slika aplikacije TF Trader" />
                      <video
                        ref={videoRef}
                        src={videoMockup}
                        autoPlay
                        loop
                        muted
                        className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-[315.25px] h-[642.75px] rounded-[40px]'
                        playsInline
                        style={{
                          objectFit: "cover",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </motion.div>

                </div>
              </> :
                <div className='lg:flex items-center justify-between'>
                  <motion.div
                    key="notUsingTf"
                    initial="hiddenLeft"
                    animate="visible"
                    exit="exitLeft"
                    variants={contentVariants}
                    className=""
                  >
                    <div className='mx-auto'>
                      <div className='max-w-[488px] mx-auto'>
                        <h2 className='font-bold  text-[30px] leading-[41.6px] pb-[16px] pt-[60px] pr-[10px]'>Otvori TF Trader nalog i aktiviraj TF Štednju</h2>
                        <p className='text-[16px] lg:text-[16px] lg:leading-[25.2px] leading-[19.2px] text-[#D9D9D9] mb-[64px]'>Ukoliko ti je potrebna pomoć, ostavi nam kontakt podatke ispod ove sekcije ili pogledaj youtube video.</p>
                      </div>

                      <div>
                        <div className='flex items-start justify-start gap-[24px]'>
                          <svg className='flex-shrink-0' width="48" height="345" viewBox="0 0 48 325" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="8" width="32" height="32" rx="16" fill="#1200D4" />
                            <path d="M25.9574 9.90909V23H24.3722V11.571H24.2955L21.0994 13.6932V12.0824L24.3722 9.90909H25.9574Z" fill="white" />
                            <line x1="25" y1="40.8335" x2="25" y2="88.8335" stroke="#1200D4" stroke-width="2" />
                            <rect x="8" y="97.6665" width="32" height="32" rx="16" fill="#E4E4E4" fill-opacity="0.5" />
                            <path d="M20.3551 120.667V119.516L24.6761 114.786C25.1832 114.232 25.6009 113.75 25.929 113.341C26.2571 112.928 26.5 112.54 26.6577 112.178C26.8196 111.811 26.9006 111.428 26.9006 111.027C26.9006 110.567 26.7898 110.169 26.5682 109.832C26.3509 109.495 26.0526 109.235 25.6733 109.052C25.294 108.869 24.8679 108.777 24.3949 108.777C23.892 108.777 23.4531 108.882 23.0781 109.091C22.7074 109.295 22.4197 109.583 22.2152 109.953C22.0149 110.324 21.9148 110.759 21.9148 111.257H20.4062C20.4062 110.49 20.5831 109.817 20.9368 109.238C21.2905 108.658 21.772 108.206 22.3814 107.882C22.995 107.559 23.6832 107.397 24.446 107.397C25.2131 107.397 25.8928 107.559 26.4851 107.882C27.0774 108.206 27.5419 108.643 27.8786 109.193C28.2152 109.742 28.3835 110.354 28.3835 111.027C28.3835 111.509 28.2962 111.98 28.1214 112.44C27.951 112.896 27.6527 113.405 27.2266 113.968C26.8047 114.526 26.2188 115.208 25.4688 116.013L22.5284 119.158V119.26H28.6136V120.667H20.3551Z" fill="black" />
                            <line x1="25" y1="138.5" x2="25" y2="186.5" stroke="#E4E4E4" stroke-opacity="0.5" stroke-width="2" />
                            <rect x="8" y="195.333" width="32" height="32" rx="16" fill="#E4E4E4" fill-opacity="0.5" />
                            <path d="M23.7784 218.512C22.9347 218.512 22.1825 218.368 21.522 218.078C20.8658 217.788 20.3438 217.385 19.956 216.87C19.5724 216.35 19.3636 215.747 19.3295 215.061H20.9403C20.9744 215.483 21.1193 215.847 21.375 216.154C21.6307 216.456 21.9652 216.691 22.3786 216.857C22.7919 217.023 23.25 217.106 23.7528 217.106C24.3153 217.106 24.8139 217.008 25.2486 216.812C25.6832 216.616 26.0241 216.343 26.2713 215.994C26.5185 215.645 26.642 215.24 26.642 214.78C26.642 214.298 26.5227 213.874 26.2841 213.508C26.0455 213.137 25.696 212.847 25.2358 212.638C24.7756 212.429 24.2131 212.325 23.5483 212.325H22.5V210.919H23.5483C24.0682 210.919 24.5241 210.825 24.9162 210.637C25.3125 210.45 25.6214 210.186 25.843 209.845C26.0689 209.504 26.1818 209.103 26.1818 208.643C26.1818 208.2 26.0838 207.814 25.8878 207.486C25.6918 207.158 25.4148 206.902 25.0568 206.719C24.7031 206.536 24.2855 206.444 23.804 206.444C23.3523 206.444 22.9261 206.527 22.5256 206.694C22.1293 206.856 21.8054 207.092 21.554 207.403C21.3026 207.71 21.1662 208.081 21.1449 208.515H19.6108C19.6364 207.829 19.843 207.228 20.2308 206.713C20.6186 206.193 21.1257 205.788 21.7521 205.498C22.3828 205.208 23.0753 205.064 23.8295 205.064C24.6392 205.064 25.3338 205.228 25.9134 205.556C26.4929 205.88 26.9382 206.308 27.2493 206.841C27.5604 207.373 27.7159 207.949 27.7159 208.566C27.7159 209.304 27.522 209.932 27.1342 210.452C26.7507 210.972 26.2287 211.332 25.5682 211.532V211.635C26.3949 211.771 27.0405 212.123 27.505 212.689C27.9695 213.252 28.2017 213.949 28.2017 214.78C28.2017 215.491 28.0078 216.13 27.62 216.697C27.2365 217.26 26.7124 217.703 26.0476 218.027C25.3828 218.351 24.6264 218.512 23.7784 218.512Z" fill="black" />
                            <line x1="25" y1="236.167" x2="25" y2="284.167" stroke="#E4E4E4" stroke-opacity="0.5" stroke-width="2" />
                            <rect x="8" y="293" width="32" height="32" rx="16" fill="#E4E4E4" fill-opacity="0.5" />
                            <path d="M19.0483 313.315V312.011L24.8011 302.909H25.7472V304.929H25.108L20.7614 311.807V311.909H28.5085V313.315H19.0483ZM25.2102 316V312.919V312.312V302.909H26.7188V316H25.2102Z" fill="black" />
                          </svg>

                          <div className='flex flex-col items-start justify-start max-w-[287px] lg:max-w-[450px] gap-[24px] lg:gap-[49px]'>
                            <div className='flex flex-col max-w-[343px] items-start justify-start gap-[4px] '>
                              <p className='text-[16px] leading-[24px]'>Preuzmi mobilnu aplikaciju klikom na link u nastavku: <a href='https://onelink.to/48kpsn' className='text-[#3E6BE4] underline' target='_blank'>TF Trader - Your investment ally.</a></p>
                            </div>
                            <div className='flex flex-col items-start justify-start gap-[4px]'>
                              <p className='text-[15px] leading-[24px]'>Kreiraj svoj nalog na TF Traderu i unesi tražene podatke. Vodič za registraciju pogledaj <a href='https://www.youtube.com/watch?v=lAKyx-WpU48' className='text-[#3E6BE4] underline' target='_blank'>ovdje.</a></p>
                            </div>
                            <div className='flex flex-col items-start justify-start gap-[4px]'>
                              <p className='text-[16px] leading-[24px]'>Deponuj novac uplatom na klijentski račun. Informacije za uplatu depozita možeš pronaći u samoj aplikaciji.</p>
                            </div>
                            <div className='flex flex-col items-start justify-start gap-[4px] pt-[0px] lg:pt-2'>
                              <p className='text-[16px] leading-[24px]'>Aktiviraj TF Štednju u meniju i zarađuj kamatu svakog dana, ili iskoristi taj novac za investiranje.</p>
                            </div>
                          </div>
                        </div>
                        <div className='flex items-center justify-start gap-[16px]'>
                          <a href='https://onelink.to/48kpsn' className=' lg:w-[187px] w-full'><button className='w-full text-[14px] hover:bg-[#335dcc] leading-[14px] h-[40px] bg-[#3E6BE4] mt-[48px] lg:w-[187px] rounded-full'>Preuzmi TF Trader</button></a>
                          <a href='https://www.youtube.com/watch?v=lAKyx-WpU48' target='_blank' className='lg:w-[187px] w-full'><button className='w-full hover:bg-[white] hover:text-black hover:border-0 text-[14px] leading-[14px] h-[40px] border-[1px] border-[#FFFFFF] text-white mt-[48px] lg:w-[187px] rounded-full'>Primjer registracija</button></a>

                        </div>
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    key="notUsingTf2"
                    initial="hiddenRight"
                    animate="visible"
                    exit="exitRight"
                    variants={contentVariants}
                    className=""
                  >
                    <div className='flex items-center justify-center mt-[64px] mx-auto h-[315.25px] h-[652.75px]'>
                      <img src={iphoneMock} className='h-[315.25px] h-[652.75px]' alt="Slika aplikacije TF Trader" />
                    </div>
                  </motion.div>

                </div>
            }
          </AnimatePresence>

        </div>
      </div>
      <div className='relative overflow-hidden lg:pt-[40px]'>
        <div className='lg:block hidden max-w-[590px] text-center mx-auto'>
          <h2 className='pt-[63.5px] font-bold text-[32px] lg:text-[42px] lg:leading-[50.4px] leading-[41.6px] pb-[20px]'>Želiš da saznaš sve detalje
            o TF Štednji?</h2>
          <p className='text-[16px] max-w-[544px] lg:text-[18px] lg:leading-[25.2px] mx-auto leading-[19.2px] text-[#D9D9D9] mb-[20px]'>Uvijek smo tu za razgovor na našem jeziku — bez neprijatnih poziva na stranom jeziku!</p>
        </div>
        <div className='px-[16px] max-w-[390px] mx-auto'>
          <ContactSectionNew showTitle={false} />
        </div>
        <div style={{ boxShadow: "0px 0px 200px 16px #00D3FF" }} className="w-[70%] h-[0px] absolute bottom-0 transform -translate-x-1/2 left-1/2 bg-[#00D3FF]">
        </div>
      </div>
    </div >
  );
};

export default Saving; 