import carbonSendIcon from "../../assets/carbon_send.svg";
import { useTranslation } from "react-i18next";
import { signup, getContacts } from "../../api/repositories/newsletterSignup";
import { useMutation } from "@tanstack/react-query";
import { Formik, Field, Form } from "formik";
import { useEffect, useState } from "react";

const SendLucide = () => { return (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-send"><path d="M14.536 21.686a.5.5 0 0 0 .937-.024l6.5-19a.496.496 0 0 0-.635-.635l-19 6.5a.5.5 0 0 0-.024.937l7.93 3.18a2 2 0 0 1 1.112 1.11z" /><path d="m21.854 2.147-10.94 10.939" /></svg>); };
const SpinnerLucide = ({ className }) => { return (<svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M21 12a9 9 0 1 1-6.219-8.56" /></svg>); };
const SuccessLucide = ({ className }) => { return (<svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><path d="M21.801 10A10 10 0 1 1 17 3.335" /><path d="m9 11 3 3L22 4" /></svg>); };

const countryCodes = [
	{ name: 'Montenegro', code: '+382', flag: '🇲🇪' },
	{ name: 'Serbia', code: '+381', flag: '🇷🇸' },
	{ name: 'Croatia', code: '+385', flag: '🇭🇷' },
	{ name: 'Bosnia and Herzegovina', code: '+387', flag: '🇧🇦' },
	{ name: 'United States', code: '+1', flag: '🇺🇸' },
	{ name: 'United Kingdom', code: '+44', flag: '🇬🇧' },
	{ name: 'Germany', code: '+49', flag: '🇩🇪' },
	{ name: 'France', code: '+33', flag: '🇫🇷' },
	{ name: 'Italy', code: '+39', flag: '🇮🇹' },
	{ name: 'Spain', code: '+34', flag: '🇪🇸' },
	{ name: 'Portugal', code: '+351', flag: '🇵🇹' },
	{ name: 'Russia', code: '+7', flag: '🇷🇺' },
	{ name: 'China', code: '+86', flag: '🇨🇳' },
	{ name: 'India', code: '+91', flag: '🇮🇳' },
	{ name: 'Japan', code: '+81', flag: '🇯🇵' },
	{ name: 'Australia', code: '+61', flag: '🇦🇺' },
	{ name: 'Brazil', code: '+55', flag: '🇧🇷' },
	{ name: 'Canada', code: '+1', flag: '🇨🇦' },
	{ name: 'Mexico', code: '+52', flag: '🇲🇽' },
	{ name: 'Argentina', code: '+54', flag: '🇦🇷' },
	{ name: 'Chile', code: '+56', flag: '🇨🇱' },
	{ name: 'South Africa', code: '+27', flag: '🇿🇦' },
	{ name: 'Egypt', code: '+20', flag: '🇪🇬' },
	{ name: 'Turkey', code: '+90', flag: '🇹🇷' },
	{ name: 'Greece', code: '+30', flag: '🇬🇷' },
	{ name: 'Poland', code: '+48', flag: '🇵🇱' },
	{ name: 'Netherlands', code: '+31', flag: '🇳🇱' },
	{ name: 'Belgium', code: '+32', flag: '🇧🇪' },
	{ name: 'Austria', code: '+43', flag: '🇦🇹' },
	{ name: 'Switzerland', code: '+41', flag: '🇨🇭' },
	{ name: 'Sweden', code: '+46', flag: '🇸🇪' },
	{ name: 'Norway', code: '+47', flag: '🇳🇴' },
	{ name: 'Denmark', code: '+45', flag: '🇩🇰' },
	{ name: 'Finland', code: '+358', flag: '🇫🇮' },
	{ name: 'Iceland', code: '+354', flag: '🇮🇸' },
	{ name: 'Ireland', code: '+353', flag: '🇮🇪' },
	{ name: 'Czech Republic', code: '+420', flag: '🇨🇿' },
	{ name: 'Slovakia', code: '+421', flag: '🇸🇰' },
	{ name: 'Hungary', code: '+36', flag: '🇭🇺' },
	{ name: 'Romania', code: '+40', flag: '🇷🇴' },
	{ name: 'Bulgaria', code: '+359', flag: '🇧🇬' },
	{ name: 'Ukraine', code: '+380', flag: '🇺🇦' },
	{ name: 'Belarus', code: '+375', flag: '🇧🇾' },
	{ name: 'Lithuania', code: '+370', flag: '🇱🇹' },
	{ name: 'Latvia', code: '+371', flag: '🇱🇻' },
	{ name: 'Estonia', code: '+372', flag: '🇪🇪' },
	{ name: 'New Zealand', code: '+64', flag: '🇳🇿' },
	{ name: 'South Korea', code: '+82', flag: '🇰🇷' },
	{ name: 'Singapore', code: '+65', flag: '🇸🇬' },
	{ name: 'Malaysia', code: '+60', flag: '🇲🇾' },
	{ name: 'Indonesia', code: '+62', flag: '🇮🇩' },
];

function Newsletter() {
	const { t } = useTranslation("usaBerza");
	const [email, setEmail] = useState("");
	const [error, setError] = useState(false);
	const [emailExsists, setEmailExsists] = useState(false);
	const [contacts, setContacts] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
	const [phoneNumber, setPhoneNumber] = useState('');
	const [name, setName] = useState('');
	const [lastname, setLastname] = useState('');
	const [nameError, setNameError] = useState(false);
	const [lastnameError, setLastnameError] = useState(false);
	const [numberError, setNumberError] = useState(false);
	const [sendingForm, setSendingForm] = useState(false);
	const [success, setSuccess] = useState(false);
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const initialValues = {
		email: ""
	};

	const { mutate, status } = useMutation({
		mutationFn: signup,
		onSuccess: () => {
			setSuccess(true);
			setSendingForm(false);
			console.log("Uspjesno poslato");
		},
		onError: (error) => {
			console.log(error);
		},
	});

	const checkIfEmailExsists = (emailAddress) => {
		const emailExists = contacts.includes(emailAddress);
		return emailExists;
	};

	const handleSubmit = () => {
		let hasError = false;

		if (!name) {
			setNameError(true);
			hasError = true;
		} else {
			setNameError(false);
		}

		if (!lastname) {
			setLastnameError(true);
			hasError = true;
		} else {
			setLastnameError(false);
		}

		if (!phoneNumber) {
			setNumberError(true);
			hasError = true;
		} else {
			setNumberError(false);
		}

		if (!email || !emailRegex.test(email)) {
			setError(true);
			hasError = true;
		} else {
			setError(false);
		}

		if (hasError) {
			return false;
		}

		if (checkIfEmailExsists(email)) {
			setEmailExsists(true);
			throw new Error('Email already exsists');
		} else {
			if (!success) {
				setEmailExsists(false);
				const phone = `${selectedCountry.code} ${phoneNumber}`;
				const payload = { email, name, lastname, phone };

				setSendingForm(true);
				mutate(payload);
			}
			// Reset form
			// setEmail("");
			// setName("");
			// setLastname("");
			// setPhoneNumber("");
		}
	};

	useEffect(() => {
		// Fetch contacts data asynchronously
		const fetchContacts = async () => {
			try {
				// Assuming getContacts() returns a Promise that resolves with an array of contacts
				const response = await getContacts();
				// Check if response is an array before setting the state
				if (Array.isArray(response.data.email_addresses)) {
					setContacts(response.data.email_addresses);
				} else {
					console.error('Response is not an array:', response.data.email_addresses);
				}
			} catch (error) {
				console.error('Error fetching contacts:', error);
			}
		};
		// Call the fetchContacts function
		fetchContacts();
	}, []);

	return (
		<div className="w-full relative bg-[#C9F5EF] overflow-x-hidden">
			<div className="absolute top-[-60px] right-[-200px] xl:top-0 xl:right-[-0px]">
				<svg className="" width="310" height="204" viewBox="0 0 310 204" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 203.476L356.936 133.66L202.229 -59.0004L0 203.476Z" fill="url(#paint0_linear_8892_3798)" fill-opacity="0.25" />
					<defs>
						<linearGradient id="paint0_linear_8892_3798" x1="196.566" y1="145.418" x2="239.231" y2="299.557" gradientUnits="userSpaceOnUse">
							<stop stop-color="#96B4E9" />
							<stop offset="1" stop-color="#96B4E9" stop-opacity="0" />
						</linearGradient>
					</defs>
				</svg>
			</div>

			<div className="container xl:pt-[152px] xl:pb-[152px] py-[101px] mx-auto px-[16px] lg:px-[20px] flex lg:flex-row flex-col items-start justify-center xl:gap-[117px]">
				<div className="xl:pb-0 lg:max-w-[463px] flex flex-col items-start justify-between gap-[20px]">
					<span className="font-bold text-[#3E6BE4] uppercase text-[12px] leading-normal">
						{t("sectionFour.subtitle-1")}
					</span>
					<h4 className=" lg:text-[42px] lg:leading-[50.4px] text-[42px] leading-[48px] font-semibold text-[#161616]">
						{t("sectionFour.title-1")}{" "}
					</h4>
					<p className="lg:text-[16px] lg:max-w-[367px] lg:leading-[22.4px] text-[#161616]">
						{t("sectionFour.text")}
					</p>
				</div>
				<div className="lg:flex mt-[56px] lg:mt-[50px] lg:mt-0 xl:pt-0 lg:max-w-[580px] flex-col justify-between gap-[24px]">
					<div className="flex flex-row gap-[12px]">
						<Formik
							initialValues={initialValues}
							onSubmit={handleSubmit}
						>
							<Form>
								<div className="flex items-center gap-[12px]">
								</div>
								<div className="flex flex-wrap justify-center items-center justify-between gap-y-[40px]">
									<div className="relative w-full lg:w-[270px]">
										<label className="absolute text-[#788497] top-[-20px] text-[12px]">{t(`sectionFour.name`)}*</label>
										<input
											name="name"
											className="h-[48px] focus:outline-none px-[16px] mt-[4px] py-[15px] w-full text-[#788497] text-[14px] leading-[14.52px]"
											placeholder={t(`sectionFour.name`)}
											onChange={(e) => setName(e.target.value)}
										/>
										{nameError && (
											<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.nameError`)}</span>
										)}
									</div>
									<div className="relative w-full lg:w-[270px]">
										<label className="absolute text-[#788497] top-[-20px] text-[12px]">{t(`sectionFour.lastname`)}*</label>
										<input
											name="lastname"
											className="h-[48px] focus:outline-none px-[16px] mt-[4px] py-[15px] w-full text-[#788497] text-[14px] leading-[14.52px]"
											placeholder={t(`sectionFour.lastname`)}
											onChange={(e) => setLastname(e.target.value)}
										/>
										{lastnameError && (
											<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.lastnameError`)}</span>
										)}
									</div>
									<div className="relative w-full lg:w-[270px]">
										<label className="absolute text-[#788497] top-[-20px] text-[12px]">{t(`sectionFour.email`)}*</label>
										<input
											name="email"
											className="h-[48px] focus:outline-none px-[16px] mt-[4px] py-[15px] w-full text-[#788497] text-[14px] leading-[14.52px]"
											placeholder='ivan.ivanovic@gmail.com'
											onChange={(e) => setEmail(e.target.value)}
										/>
										{error && (
											<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.emailNotEntered`)}</span>
										)}
									</div>


									<div className="relative w-full lg:w-[270px]">
										<div className="flex items-center gap-[4px]">
											<label className="absolute text-[#788497] top-[-20px] text-[12px]">{t(`sectionFour.phonenumber`)}*</label>
											<div className="w-[56px] h-[48px] mt-[4px] relative">
												<select
													value={selectedCountry.code}
													onChange={(e) => {
														const country = countryCodes.find((c) => c.code === e.target.value);
														setSelectedCountry(country);
													}}
													className="h-[48px] w-[56px]"
													style={{
														appearance: 'none', // Skriva strelicu browsera
														WebkitAppearance: 'none',
														MozAppearance: 'none',
														cursor: 'pointer',
														fontSize: '32px'
													}}
												>
													{countryCodes.map((country) => (
														<option className="w-[50px] h-[46px] text-center text-[44px]" key={country.code} value={country.code}>
															{country.flag}
														</option>
													))}
												</select>
											</div>
											<div className="w-full">
												<div className="relative w-full">
													<span className="absolute top-[17px] left-[12px] text-[14px] text-[#CCD0D7]">
														+382
													</span>
													<input
														name="number"
														className="h-[48px] focus:outline-none pl-[56px] mt-[4px] pr-[16px] py-[15px] w-full text-[#788497] text-[14px] leading-[14.52px]"
														placeholder='69 123 456'
														onChange={(e) => setPhoneNumber(e.target.value)}
													/>
												</div>

											</div>
										</div>
										{numberError && (
											<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.numberError`)}</span>
										)}
									</div>

								</div>
								{emailExsists && (
									<span className="text-[#FD5869] text-[12px]">{t(`sectionFour.emailExsists`)}</span>
								)}
								<button disabled={success} className="relative z-[1] mt-[46px] w-full h-[48px] bg-[#3E6BE4] hover:bg-[#335dcc] text-white">
									{!sendingForm && !success && <span className="flex items-center gap-[10px] justify-center">Prijavi se <SendLucide /></span>}
									{<SpinnerLucide className={`${sendingForm ? 'block' : 'hidden'} animate-spin mx-auto`} />}
									{<SuccessLucide className={`${success ? 'block' : 'hidden'} tranisiton mx-auto`} />}
								</button>
							</Form>
						</Formik>
					</div>
				</div>
			</div>
			<svg className="absolute bottom-0 z-[0] left-0" width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 128.472L22.4299 150L119.159 54.1667V107.639H150V16.6667C150 4.16667 141.589 0 134.579 0H42.0561V31.25H97.4299L0 128.472Z" fill="url(#paint0_linear_8912_4992)" fill-opacity="0.25" />
				<defs>
					<linearGradient id="paint0_linear_8912_4992" x1="75.1295" y1="75.1295" x2="12.6943" y2="135.363" gradientUnits="userSpaceOnUse">
						<stop stop-color="#96B4E9" />
						<stop offset="1" stop-color="#96B4E9" stop-opacity="0" />
					</linearGradient>
				</defs>
			</svg>

		</div>
	);
}

export default Newsletter;
